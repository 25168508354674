import PropTypes from 'prop-types';
import { Navigate, useLocation } from 'react-router-dom';

import { useSelector } from 'react-redux';
// routes
import { PATH_DASHBOARD } from '../routes/paths';

// ----------------------------------------------------------------------

GuestGuard.propTypes = {
  children: PropTypes.node,
};

export default function GuestGuard({ children }) {
  const location = useLocation();
  const { isAuthenticated } = useSelector((state) => state.auth);
  const { loading } = useSelector((state) => state.bookings);

  if (isAuthenticated && !loading) {
    // Method 1
    // return navigate(location?.state?.from ? location.state.from : PATH_DASHBOARD.root);

    // Method 2
    return (
      <Navigate
        to={location?.state?.from ? location?.state?.from?.pathname : PATH_DASHBOARD.general.customerFeedback}
      />
    );
  }

  // if (!isInitialized) {
  //   return <LoadingScreen />;
  // }

  return <>{children}</>;
}
