import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';
import CaptainTripHistory from '../pages/dashboard/captain-mgt/captains/CaptainTripHistory';
import Deductions from '../pages/dashboard/captain-mgt/captain-salary/Deductions';
import CaptainBonuses from '../pages/dashboard/captain-mgt/captain-salary/CaptainBonuses';
import CaptainPaymentDetails from '../pages/dashboard/captain-mgt/captain-salary/CaptainPaymentDetails';
import CaptianRatingApproval from '../pages/dashboard/captain-mgt/captain-salary/CaptainRatingApproval';
import CaptainRatingBonus from '../pages/dashboard/captain-mgt/captain-salary/CaptainRatingBonus';
import MaintenanceIncome from '../pages/dashboard/captain-mgt/captain-salary/MaintenanceIncome';
import RoleBasedGuard from '../guards/RoleBasedGuard';
// import Ticket from 'src/pages/dashboard/Ticket';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [{ element: <GeneralApp />, index: true }],
    },
    {
      path: '/',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },

        { path: 'login-unprotected', element: <Login /> },
        { path: 'forgot-password', element: <ForgotPassword /> },
        { path: 'reset-password', element: <ResetPassword /> },
      ],
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        {
          path: 'overview',
          element: (
            <RoleBasedGuard
              hasContent
              roles={['SuperAdmin', 'Administrator', 'GroupAccountant', 'AuditSupervisor', 'OperationHead']}
            >
              <GeneralApp />
            </RoleBasedGuard>
          ),
        },
        {
          path: 'general',
          children: [
            { element: <Navigate to="/dashboard/general/customer-support" replace />, index: true },
            // { path: 'app', element: <GeneralApp /> },
            { path: 'customer-support', element: <CustomerSupport /> },
            { path: 'payment-reversal', element: <PaymentReversal /> },
            // { path: 'account', element: <ManageAccount /> },
            // { path: 'audit', element: <AuditLog /> },
            // { path: 'customer-records', element: <CustomerRecord /> },
            // { path: 'customer-booking-history/:id', element: <CustomerBookingHistory /> },
            // { path: 'customer-wallet-history/:id', element: <CustomerWalletHistory /> },
            // { path: 'customer-billPayment-history/:id', element: <CustomerBillPayment /> },
          ],
        },
        {
          path: 'portal',
          children: [
            { element: <Navigate to="/dashboard/portal/account" replace />, index: true },
            {
              path: 'account',
              element: (
                <RoleBasedGuard hasContent roles={['HumanResource', 'SuperAdmin', 'Administrator']}>
                  <ManageUserAccount />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'audit',
              element: (
                <RoleBasedGuard hasContent roles={['HumanResource', 'SuperAdmin', 'Administrator', 'AuditSupervisor']}>
                  <AuditLog />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'customer-records',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={['HumanResource', 'SuperAdmin', 'Administrator', 'HeadOfCustomerService']}
                >
                  <CustomerRecord />
                </RoleBasedGuard>
              ),
            },

            {
              path: 'customer-booking-history/:email',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'SuperAdmin',
                    'Administrator',
                    'OperationsManager',
                    'Ticketer',
                    'CustomerRepSupervisor',
                    'HumanResource',
                    'RegionalManager',
                    'OnlineBookingRepresentative',
                    'HeadOfCustomerService',
                  ]}
                >
                  <CustomerBookingHistory />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'customer-wallet-history/:email',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'SuperAdmin',
                    'Administrator',
                    'OperationsManager',
                    'Ticketer',
                    'CustomerRepSupervisor',
                    'HumanResource',
                    'RegionalManager',
                    'OnlineBookingRepresentative',
                    'HeadOfCustomerService',
                  ]}
                >
                  <CustomerWalletHistory />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'customer-billPayment-history/:email',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={['SuperAdmin', 'Administrator', 'Auditor', 'AuditSupervisor', 'GroupAccountant']}
                >
                  <CustomerBillPayment />{' '}
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: 'bookings',
          children: [
            { element: <Navigate to="/dashboard/bookings/state" replace />, index: true },
            {
              path: 'state',
              element: (
                <RoleBasedGuard hasContent roles={['SuperAdmin', 'Administrator']}>
                  <StateSettings />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'region',
              element: (
                <RoleBasedGuard hasContent roles={['SuperAdmin', 'Administrator']}>
                  <RegionSettings />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'country',
              element: (
                <RoleBasedGuard hasContent roles={['SuperAdmin', 'Administrator']}>
                  <CountrySettings />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'department',
              element: (
                <RoleBasedGuard hasContent roles={['SuperAdmin', 'Administrator']}>
                  <Department />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'trips',
              element: (
                <RoleBasedGuard hasContent roles={['SuperAdmin', 'Administrator', 'RegionalManager']}>
                  <TripsSettings />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'trip-details/:id/:time',
              element: (
                <RoleBasedGuard hasContent roles={['SuperAdmin', 'Administrator', 'RegionalManager']}>
                  <TripDetails />{' '}
                </RoleBasedGuard>
              ),
            },

            {
              path: 'route',
              element: (
                <RoleBasedGuard hasContent roles={['SuperAdmin', 'Administrator', 'RegionalManager']}>
                  <RouteSettings />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'fixedCharges',
              element: (
                <RoleBasedGuard hasContent roles={['SuperAdmin', 'Administrator']}>
                  <FixedCharges />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'fuelCost',
              element: (
                <RoleBasedGuard hasContent roles={['SuperAdmin', 'Administrator']}>
                  <FuelCost />{' '}
                </RoleBasedGuard>
              ),
            },

            {
              path: 'discount',
              element: (
                <RoleBasedGuard hasContent roles={['SuperAdmin', 'Administrator', 'FleetManager']}>
                  <Discount />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'vehicle-model',
              element: (
                <RoleBasedGuard hasContent roles={['SuperAdmin', 'Administrator', 'FleetManager']}>
                  <VehicleModel />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'vehicle-make',
              element: (
                <RoleBasedGuard hasContent roles={['SuperAdmin', 'Administrator', 'FleetManager']}>
                  <VehicleMake />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'bank',
              element: (
                <RoleBasedGuard hasContent roles={['SuperAdmin', 'Administrator', 'FleetManager']}>
                  <BankSettings />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'banner',
              element: (
                <RoleBasedGuard hasContent roles={['SuperAdmin', 'Administrator']}>
                  <BannerSettings />{' '}
                </RoleBasedGuard>
              ),
            },

            {
              path: 'pickup-point',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'SuperAdmin',
                    'Administrator',
                    'FleetManager',
                    'OnlineBookingRepresentative',
                    'OperationHead',
                  ]}
                >
                  <PickupPoint />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'busFeature',
              element: (
                <RoleBasedGuard hasContent roles={['SuperAdmin', 'Administrator', 'FleetManager']}>
                  <BusFeature />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'extendedDiscount',
              element: (
                <RoleBasedGuard hasContent roles={['SuperAdmin', 'Administrator', 'FleetManager']}>
                  <ExtendedDiscount />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'coupon',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'SuperAdmin',
                    'Administrator',
                    'FleetManager',
                    'CustomerRepresentative',
                    'CustomerRepSupervisor',
                  ]}
                >
                  <Coupon />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'sub-route',
              element: (
                <RoleBasedGuard hasContent roles={['SuperAdmin', 'Administrator', 'RegionalManager']}>
                  <SubRoute />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'dispatch-partner-type',
              element: (
                <RoleBasedGuard hasContent roles={['SuperAdmin', 'Administrator']}>
                  <DispatchPartnerType />{' '}
                </RoleBasedGuard>
              ),
            },

            {
              path: 'dispatch-fee',
              element: (
                <RoleBasedGuard hasContent roles={['SuperAdmin', 'Administrator', 'RegionalManager']}>
                  <DispatchFee />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'partner',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'SuperAdmin',
                    'Administrator',
                    'GroupAccountant',
                    'Auditor',
                    'AuditSupervisor',
                    'PartnerRelationsManager',
                    'EnterpriseBusinessManager',
                  ]}
                >
                  <Partner />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'booking-channel-report',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'SuperAdmin',
                    'Administrator',
                    'GroupAccountant',
                    'Auditor',
                    'AuditSupervisor',
                    'PartnerRelationsManager',
                    'EnterpriseBusinessManager',
                    'OperationsManager',
                  ]}
                >
                  <BookingChannelReport />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'partner-request',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'SuperAdmin',
                    'Administrator',
                    'GroupAccountant',
                    'Auditor',
                    'AuditSupervisor',
                    'PartnerRelationsManager',
                    'EnterpriseBusinessManager',
                  ]}
                >
                  <PartnerRequest />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'partner-payout',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'SuperAdmin',
                    'Administrator',
                    'GroupAccountant',
                    'Auditor',
                    'AuditSupervisor',
                    'PartnerRelationsManager',
                    'EnterpriseBusinessManager',
                  ]}
                >
                  <PartnerPayout />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'partner-transaction-history',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'SuperAdmin',
                    'Administrator',
                    'GroupAccountant',
                    'Auditor',
                    'AuditSupervisor',
                    'PartnerRelationsManager',
                    'EnterpriseBusinessManager',
                  ]}
                >
                  <PartnerTransaction />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'partner-sales-summary',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'SuperAdmin',
                    'Administrator',
                    'GroupAccountant',
                    'Auditor',
                    'AuditSupervisor',
                    'PartnerRelationsManager',
                    'EnterpriseBusinessManager',
                  ]}
                >
                  <PartnerSalesSummary />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'business-partner',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'SuperAdmin',
                    'Administrator',
                    'GroupAccountant',
                    'Auditor',
                    'AuditSupervisor',
                    'PartnerRelationsManager',
                    'EnterpriseBusinessManager',
                  ]}
                >
                  <BusinessPartner />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'business-partner-booking/:businessPartnerCode',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'SuperAdmin',
                    'Administrator',
                    'GroupAccountant',
                    'Auditor',
                    'AuditSupervisor',
                    'PartnerRelationsManager',
                    'EnterpriseBusinessManager',
                  ]}
                >
                  <BusinessPartnerBooking />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'business-partner-payment/:businessPartnerCode',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'SuperAdmin',
                    'Administrator',
                    'GroupAccountant',
                    'Auditor',
                    'AuditSupervisor',
                    'PartnerRelationsManager',
                    'EnterpriseBusinessManager',
                  ]}
                >
                  <BusinessPartnerPayment />{' '}
                </RoleBasedGuard>
              ),
            },

            {
              path: 'payment-gateway',
              element: (
                <RoleBasedGuard hasContent roles={['SuperAdmin', 'Administrator']}>
                  <PaymentGateWay />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'terminal',
              element: (
                <RoleBasedGuard hasContent roles={['SuperAdmin', 'Administrator']}>
                  <TerminalSettings />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'fare',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'SuperAdmin',
                    'Administrator',
                    'OnlineBookingManager',
                    'CustomerRepSupervisor',
                    'OnlineBookingRepresentative',
                    'RegionalManager',
                    'CustomerRepSupervisor',
                    'Ticketer',
                  ]}
                >
                  <FareSettings />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'fareCalendar',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'SuperAdmin',
                    'Administrator',
                    'OnlineBookingManager',
                    'CustomerRepSupervisor',
                    'OnlineBookingRepresentative',
                    'RegionalManager',
                    'CustomerRepSupervisor',
                    'Ticketer',
                  ]}
                >
                  <FareCalendar />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'fleetMgtSettings',
              element: (
                <RoleBasedGuard hasContent roles={['SuperAdmin', 'Administrator']}>
                  <FleetMgtSettings />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'currencyConverter',
              element: (
                <RoleBasedGuard hasContent roles={['SuperAdmin', 'Administrator']}>
                  <CurrencyConverter />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'trip-availability',
              element: (
                <RoleBasedGuard hasContent roles={['SuperAdmin', 'Administrator']}>
                  <TripAvailability />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'travel-document-type',
              element: (
                <RoleBasedGuard hasContent roles={['SuperAdmin', 'Administrator']}>
                  <TravelDocumentType />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'travel-document-fee',
              element: (
                <RoleBasedGuard hasContent roles={['SuperAdmin', 'Administrator']}>
                  <TravelDocumentFee />{' '}
                </RoleBasedGuard>
              ),
            },
          ],
        },

        {
          path: 'campusAmbassador',
          children: [
            { element: <Navigate to="/dashboard/bookings/state" replace />, index: true },
            {
              path: 'state',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'SuperAdmin',
                    'Administrator',
                    'OperationsManager',
                    'Ticketer',
                    'CustomerRepSupervisor',
                    'HumanResource',
                    'RegionalManager',
                    'OnlineBookingRepresentative',
                  ]}
                >
                  <StateSettings />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'ambassador-home',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={['SuperAdmin', 'Administrator', 'EnterpriseBusinessManager', 'PartnerRelationsManager']}
                >
                  <CampusHome />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: `ambassador-detail/:id`,
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={['SuperAdmin', 'Administrator', 'EnterpriseBusinessManager', 'PartnerRelationsManager']}
                >
                  <ViewAmbassadorDetails />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'ambassador-booking/:email',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={['SuperAdmin', 'Administrator', 'EnterpriseBusinessManager', 'PartnerRelationsManager']}
                >
                  <AmbassadorBookingHistory />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'ambassador-ticket-booking',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={['SuperAdmin', 'Administrator', 'EnterpriseBusinessManager', 'PartnerRelationsManager']}
                >
                  <AmbassadorTicketHistory />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'ambassador-withdrawal',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={['SuperAdmin', 'Administrator', 'EnterpriseBusinessManager', 'PartnerRelationsManager']}
                >
                  <AmbassadorWithdrawal />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'ambassador-report',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={['SuperAdmin', 'Administrator', 'EnterpriseBusinessManager', 'PartnerRelationsManager']}
                >
                  <AmbassadorReport />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'verify-ambassador-booking',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={['SuperAdmin', 'Administrator', 'EnterpriseBusinessManager', 'PartnerRelationsManager']}
                >
                  <VerifyAmbasadorBooking />{' '}
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: 'tracking',
          children: [
            { element: <Navigate to="/dashboard/tracking/track-bus" replace />, index: true },
            {
              path: 'track-bus',
              element: (
                <RoleBasedGuard hasContent>
                  <TrackABus />{' '}
                </RoleBasedGuard>
              ),
            },
            // {
            //   path: 'track-bus/:t/:i',
            //   element: (
            //     <TrackBusById />
            //   ),
            // },
          ],
        },
        {
          path: 'captain',
          children: [
            { element: <Navigate to="/dashboard/captain/captains" replace />, index: true },
            {
              path: 'captains',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'SuperAdmin',
                    'Administrator',
                    'Auditor',
                    'AuditSupervisor',
                    'GroupAccountant',
                    'Captain Relation Officer',
                    'Captain Relation Manager',
                    'OperationsManager',
                    'OperationHead',
                  ]}
                >
                  <CaptainSettings />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'captain-classification',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'SuperAdmin',
                    'Administrator',
                    'Auditor',
                    'AuditSupervisor',
                    'GroupAccountant',
                    'Captain Relation Manager',
                    'Captain Relation Officer',
                  ]}
                >
                  <CaptainClassification />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'captain-request',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'SuperAdmin',
                    'Administrator',
                    'Auditor',
                    'AuditSupervisor',
                    'GroupAccountant',
                    'Captain Relation Manager',
                    'Captain Relation Officer',
                  ]}
                >
                  <CaptainRequest />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'captain-swap',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'SuperAdmin',
                    'Administrator',
                    'Auditor',
                    'AuditSupervisor',
                    'GroupAccountant',
                    'Captain Relation Manager',
                    'Captain Relation Officer',
                  ]}
                >
                  <CaptainSwap />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'locked-out-captain',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'SuperAdmin',
                    'Administrator',
                    'Auditor',
                    'AuditSupervisor',
                    'GroupAccountant',
                    'Captain Relation Manager',
                    'Captain Relation Officer',
                  ]}
                >
                  <LockedOutCaptain />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'captains-with-17-trips',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'SuperAdmin',
                    'Administrator',
                    'Auditor',
                    'AuditSupervisor',
                    'GroupAccountant',
                    'Captain Relation Manager',
                    'Captain Relation Officer',
                  ]}
                >
                  <CaptainWith17Trip />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'captain-maintenance',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'SuperAdmin',
                    'Administrator',
                    'Auditor',
                    'AuditSupervisor',
                    'GroupAccountant',
                    'Captain Relation Manager',
                    'Captain Relation Officer',
                  ]}
                >
                  <CaptainMaintenance />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'view-captain/:id',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'SuperAdmin',
                    'Administrator',
                    'Auditor',
                    'AuditSupervisor',
                    'GroupAccountant',
                    'Captain Relation Manager',
                    'Captain Relation Officer',
                  ]}
                >
                  <ViewCaptainSettings />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'view-captain-trips/:id',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'SuperAdmin',
                    'Administrator',
                    'Auditor',
                    'AuditSupervisor',
                    'GroupAccountant',
                    'Captain Relation Officer',
                    'Captain Relation Manager',
                  ]}
                >
                  <CaptainTripHistory />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'view-captain-records/:id',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'SuperAdmin',
                    'Administrator',
                    'Auditor',
                    'AuditSupervisor',
                    'GroupAccountant',
                    'Captain Relation Officer',
                    'Captain Relation Manager',
                  ]}
                >
                  <CaptainRecordsSettings />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'view-captain-trip',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'SuperAdmin',
                    'Administrator',
                    'Auditor',
                    'AuditSupervisor',
                    'GroupAccountant',
                    'Captain Relation Manager',
                    'Captain Relation Officer',
                  ]}
                >
                  <CaptainRecord />{' '}
                </RoleBasedGuard>
              ),
            },
            //
            {
              path: 'deductions',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'SuperAdmin',
                    'Administrator',
                    'Auditor',
                    'AuditSupervisor',
                    'GroupAccountant',
                    'Captain Relation Manager',
                    'Captain Relation Officer',
                  ]}
                >
                  <Deductions />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'other-income',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'SuperAdmin',
                    'Administrator',
                    'Auditor',
                    'AuditSupervisor',
                    'GroupAccountant',
                    'Captain Relation Manager',
                    'Captain Relation Officer',
                  ]}
                >
                  <CaptainBonuses />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'salary',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'SuperAdmin',
                    'Administrator',
                    'Auditor',
                    'AuditSupervisor',
                    'GroupAccountant',
                    'Captain Relation Manager',
                    'Captain Relation Officer',
                  ]}
                >
                  <CaptainSalary />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'payment-details',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'SuperAdmin',
                    'Administrator',
                    'Auditor',
                    'AuditSupervisor',
                    'GroupAccountant',
                    'Captain Relation Manager',
                    'Captain Relation Officer',
                  ]}
                >
                  <CaptainPaymentDetails />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'maintenance-incomes',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={['SuperAdmin', 'Administrator', 'WorkshopSupervisor', 'WorkshopAdministrator']}
                >
                  <MaintenanceIncome />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'captain-rating-approval',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'SuperAdmin',
                    'Administrator',
                    'Auditor',
                    'AuditSupervisor',
                    'GroupAccountant',
                    'Captain Relation Manager',
                  ]}
                >
                  <CaptianRatingApproval />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'bonus-rating-report',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'SuperAdmin',
                    'Administrator',
                    'Auditor',
                    'AuditSupervisor',
                    'GroupAccountant',
                    'Captain Relation Manager',
                    'Captain Relation Officer',
                  ]}
                >
                  <CaptainRatingBonus />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'overspeed-report',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'SuperAdmin',
                    'Administrator',
                    'Auditor',
                    'AuditSupervisor',
                    'GroupAccountant',
                    'Captain Relation Manager',
                    'Captain Relation Officer',
                  ]}
                >
                  <CaptainOverspeed />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'captain-trip-report',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'SuperAdmin',
                    'Administrator',
                    'Auditor',
                    'AuditSupervisor',
                    'GroupAccountant',
                    'Captain Relation Manager',
                    'Captain Relation Officer',
                  ]}
                >
                  <CaptainTripReport />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'captain-salary-report',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'SuperAdmin',
                    'Administrator',
                    'Auditor',
                    'AuditSupervisor',
                    'GroupAccountant',
                    'Captain Relation Manager',
                    'Captain Relation Officer',
                  ]}
                >
                  <CaptainSalaryReport />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'captain-salary-report-details/:startDate/:endDate/:captainCode',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'SuperAdmin',
                    'Administrator',
                    'Auditor',
                    'AuditSupervisor',
                    'GroupAccountant',
                    'Captain Relation Manager',
                    'Captain Relation Officer',
                  ]}
                >
                  <CaptainSalaryReportDetails />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'captain-rating',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'SuperAdmin',
                    'Administrator',
                    'Auditor',
                    'AuditSupervisor',
                    'GroupAccountant',
                    'Captain Relation Manager',
                    'Captain Relation Officer',
                  ]}
                >
                  <CaptainRating />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'trip-rating/:id',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'SuperAdmin',
                    'Administrator',
                    'Auditor',
                    'AuditSupervisor',
                    'GroupAccountant',
                    'Captain Relation Manager',
                    'Captain Relation Officer',
                  ]}
                >
                  <CaptainRatingDetail />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'captain-manifest',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'SuperAdmin',
                    'Administrator',
                    'Auditor',
                    'AuditSupervisor',
                    'GroupAccountant',
                    'Captain Relation Manager',
                    'Captain Relation Officer',
                  ]}
                >
                  <CaptainManifest />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'suspended-inactive-captain',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'SuperAdmin',
                    'Administrator',
                    'Auditor',
                    'AuditSupervisor',
                    'GroupAccountant',
                    'Captain Relation Manager',
                    'Captain Relation Officer',
                  ]}
                >
                  <SuspendedInActiveCaptains />{' '}
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: 'reservation',
          children: [
            { element: <Navigate to="/dashboard/reservation/advancedBooking" replace />, index: true },
            {
              path: 'advancedBooking',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'SuperAdmin',
                    'Administrator',
                    'GroupAccountant',
                    'Auditor',
                    'AuditSupervisor',
                    'Ticketer',
                    'OperationsManager',
                    'RegionalManager',
                  ]}
                >
                  <AdvanceBooking />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'bookOnHold',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'SuperAdmin',
                    'Administrator',
                    'GroupAccountant',
                    'Auditor',
                    'AuditSupervisor',
                    'CustomerRepresentative',
                    'CustomerRepSupervisor',
                  ]}
                >
                  <BookOnHold />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'allTicketBookings',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'SuperAdmin',
                    'Administrator',
                    'GroupAccountant',
                    'Auditor',
                    'AuditSupervisor',
                    'TerminalAccountant',
                    'OperationHead',
                    'OperationsManager',
                    'Ticketer',
                    'CustomerRepresentative',
                    'CustomerRepSupervisor',
                    'RegionalManager',
                  ]}
                >
                  <AllTicketBookings />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'bookedVehicleManifest/:id',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'SuperAdmin',
                    'Administrator',
                    'GroupAccountant',
                    'Auditor',
                    'AuditSupervisor',
                    'TerminalAccountant',
                    'OperationHead',
                    'OperationsManager',
                    'Ticketer',
                    'CustomerRepresentative',
                    'CustomerRepSupervisor',
                    'RegionalManager',
                  ]}
                >
                  <BookedVehicleManifest />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'blownTicket',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'SuperAdmin',
                    'Administrator',
                    'GroupAccountant',
                    'Auditor',
                    'AuditSupervisor',
                    'TerminalAccountant',
                    'OperationHead',
                    'OperationsManager',
                    'Ticketer',
                    'RegionalManager',
                  ]}
                >
                  <BlownTicket />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'ticket/:refId',
              element: <Ticket />,
            },
            {
              path: 'rescheduleBooking/:refCode',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'SuperAdmin',
                    'Administrator',
                    'GroupAccountant',
                    'Auditor',
                    'AuditSupervisor',
                    'TerminalAccountant',
                    'OperationHead',
                    'OperationsManager',
                    'Ticketer',
                    'CustomerRepSupervisor',
                    'CustomerRepresentative',
                    'HeadOfCustomerService',
                    'RegionalManager',
                  ]}
                >
                  <RescheduleBooking />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'addSeat/:refCode',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'SuperAdmin',
                    'Administrator',
                    'GroupAccountant',
                    'Auditor',
                    'AuditSupervisor',
                    'TerminalAccountant',
                    'OperationHead',
                    'OperationsManager',
                    'Ticketer',
                    'CustomerRepSupervisor',
                    'CustomerRepresentative',
                    'HeadOfCustomerService',
                    'RegionalManager',
                  ]}
                >
                  <AddSeatToTicket />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'addPickUp/:refCode',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'SuperAdmin',
                    'Administrator',
                    'GroupAccountant',
                    'Auditor',
                    'AuditSupervisor',
                    'TerminalAccountant',
                    'OperationHead',
                    'OperationsManager',
                    'Ticketer',
                  ]}
                >
                  <AddPickUp />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'rerouteBooking/:refCode',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'SuperAdmin',
                    'Administrator',
                    'GroupAccountant',
                    'Auditor',
                    'AuditSupervisor',
                    'CustomerRepSupervisor',
                    'CustomerRepresentative',
                    'TerminalAccountant',
                    'OperationHead',
                    'OperationsManager',
                    'RegionalManager',
                    'Ticketer',
                  ]}
                >
                  <RerouteBooking />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'combined-bus',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'SuperAdmin',
                    'Administrator',
                    'GroupAccountant',
                    'Auditor',
                    'AuditSupervisor',
                    'TerminalAccountant',
                    'OperationHead',
                    'OperationsManager',
                    'Ticketer',
                  ]}
                >
                  <CombinedBus />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'combined-bus/:id',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'SuperAdmin',
                    'Administrator',
                    'GroupAccountant',
                    'Auditor',
                    'AuditSupervisor',
                    'TerminalAccountant',
                    'OperationHead',
                    'OperationsManager',
                    'Ticketer',
                  ]}
                >
                  <CombinedBusTicket />{' '}
                </RoleBasedGuard>
              ),
            },

            {
              path: 'manage-onhold-bookings',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'SuperAdmin',
                    'Administrator',
                    'GroupAccountant',
                    'Auditor',
                    'AuditSupervisor',
                    'TerminalAccountant',
                    'OperationHead',
                    'OperationsManager',
                    'Ticketer',
                    'CustomerRepresentative',
                    'CustomerRepSupervisor',
                  ]}
                >
                  <MgtOnHoldBookings />{' '}
                </RoleBasedGuard>
              ),
            },
            // {
            //   path: 'customer',
            //   element: (
            //     <RoleBasedGuard
            //       hasContent
            //       roles={[
            //       'SuperAdmin',  'Administrator',
            //         'GroupAccountant',
            //         'Auditor',
            //         'AuditSupervisor',
            //         'TerminalAccountant',
            //         'OperationHead',
            //         'OperationsManager',
            //         'Ticketer',
            //       ]}
            //     >
            //       <Customer />{' '}
            //     </RoleBasedGuard>
            //   ),
            // },
            {
              path: 'terminal-listing',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'SuperAdmin',
                    'Administrator',
                    'GroupAccountant',
                    'Auditor',
                    'AuditSupervisor',
                    'TerminalAccountant',
                    'OperationHead',
                    'OperationsManager',
                    'Ticketer',
                  ]}
                >
                  <TerminalListing />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'employee-route',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'SuperAdmin',
                    'Administrator',
                    'GroupAccountant',
                    'Auditor',
                    'AuditSupervisor',
                    'OperationHead',
                    'OperationsManager',
                    'Ticketer',
                  ]}
                >
                  <EmployeeRoute />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'terminal-route/:id',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'SuperAdmin',
                    'Administrator',
                    'GroupAccountant',
                    'Auditor',
                    'AuditSupervisor',
                    'TerminalAccountant',
                    'OperationHead',
                    'OperationsManager',
                    'Ticketer',
                  ]}
                >
                  <TerminalRoute />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'terminal-booking-route/:id',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'SuperAdmin',
                    'Administrator',
                    'GroupAccountant',
                    'Auditor',
                    'AuditSupervisor',
                    'TerminalAccountant',
                    'OperationHead',
                    'OperationsManager',
                    'Ticketer',
                  ]}
                >
                  <TerminalRoute />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'route-buses/:type/:id',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'SuperAdmin',
                    'Administrator',
                    'GroupAccountant',
                    'Auditor',
                    'AuditSupervisor',
                    'TerminalAccountant',
                    'OperationHead',
                    'OperationsManager',
                    'Ticketer',
                  ]}
                >
                  <RouteBuses />{' '}
                </RoleBasedGuard>
              ),
            },

            {
              path: 'bookedVehicle',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'SuperAdmin',
                    'Administrator',
                    'TerminalAccountant',
                    'OperationHead',
                    'OperationsManager',
                    'Ticketer',
                    'InventoryAdmin',
                    'InventoryOfficer',
                    'InventorySupervisor',
                    'CustomerRepresentative',
                    'CustomerRepSupervisor',
                    'AuditSupervisor',
                    'RegionalManager',
                  ]}
                >
                  <BookedVehicle />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'ticket-sales-records',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'SuperAdmin',
                    'Administrator',
                    'GroupAccountant',
                    'Auditor',
                    'AuditSupervisor',
                    'TerminalAccountant',
                    'Ticketer',
                    'OperationsManager',
                    'RegionalManager',
                  ]}
                >
                  <TicketSalesRecords />{' '}
                </RoleBasedGuard>
              ),
            },

            {
              path: 'terminal-booking',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'SuperAdmin',
                    'Administrator',
                    'GroupAccountant',
                    'Ticketer',
                    'OperationsManager',
                    'TerminalAccountant',
                  ]}
                >
                  <TerminalListing />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'update-payment-method',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'SuperAdmin',
                    'Administrator',
                    'GroupAccountant',
                    'Ticketer',
                    'OperationsManager',
                    'TerminalAccountant',
                  ]}
                >
                  <UpdatePaymentMethod />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'temporal-terminal-booking',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'SuperAdmin',
                    'Administrator',
                    'GroupAccountant',
                    'Ticketer',
                    'OperationsManager',
                    'TerminalAccountant',
                  ]}
                >
                  <TemporalRouteBuses />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'swap-vehicle',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'SuperAdmin',
                    'Administrator',
                    'GroupAccountant',
                    'Ticketer',
                    'OperationsManager',
                    'TerminalAccountant',
                    'RegionalManager',
                  ]}
                >
                  <SwapVehicle />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'swap-pass-vehicle',
              element: (
                <RoleBasedGuard hasContent roles={['SuperAdmin', 'Administrator', 'RegionalManager']}>
                  <SwapPassVehicle />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'booking-analysis',
              element: (
                <RoleBasedGuard hasContent roles={['SuperAdmin', 'Administrator']}>
                  <BookingAnalysis />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'booking-manager/:id',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'SuperAdmin',
                    'Administrator',
                    'GroupAccountant',
                    'Ticketer',
                    'OperationsManager',
                    'TerminalAccountant',
                  ]}
                >
                  <BookingManager />{' '}
                </RoleBasedGuard>
              ),
            },
            // Create Bus
            {
              path: 'assign-bus-to-trip',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'SuperAdmin',
                    'Administrator',
                    'OperationsManager',
                    'TerminalAccountant',
                    'OperationHead',
                    'OperationsManager',
                    'RegionalManager',
                    'TerminalOfficer',
                  ]}
                >
                  <AssignBusToTrip />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'blown-bus',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={['SuperAdmin', 'Administrator', 'RegionalManager', 'OperationHead', 'OperationsManager']}
                >
                  <BlownBus />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'blown-bus-request',
              element: (
                <RoleBasedGuard hasContent roles={['SuperAdmin', 'Administrator', 'RegionalManager', 'OperationHead']}>
                  <BlownBusRequest />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'blown-bus-report',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'SuperAdmin',
                    'Administrator',
                    'RegionalManager',
                    'OperationHead',
                    'OperationsManager',
                    'TerminalAccountant',
                    'GroupAccountant',
                  ]}
                >
                  <BlownBusReport />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'blown-bus/:journeyId',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={['SuperAdmin', 'Administrator', 'RegionalManager', 'OperationHead', 'OperationsManager']}
                >
                  <BlownBusTicket />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'register-leftover-bus',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={['SuperAdmin', 'Administrator', 'RegionalManager', 'OperationHead', 'OperationsManager']}
                >
                  <RegisterLeftoverBus />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'virtual-blown-bus',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={['SuperAdmin', 'Administrator', 'RegionalManager', 'OperationHead', 'OperationsManager']}
                >
                  <VirtualBlownBus />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'virtual-bus',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={['SuperAdmin', 'Administrator', 'RegionalManager', 'OperationHead', 'OperationsManager']}
                >
                  <VirtualBus />{' '}
                </RoleBasedGuard>
              ),
            },
            // Booking Report
            {
              path: 'advance-booking-report',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'Auditor',
                    'AuditSupervisor',
                    'SuperAdmin',
                    'Administrator',
                    'GroupAccountant',
                    'FleetManager',
                    'IntelligentControlUnit',
                    'CustomerRepresentative',
                    'CustomerRepSupervisor',
                    'OperationHead',
                    'OperationsManager',
                    'TerminalAccountant',
                    'Ticketer',
                  ]}
                >
                  <AdvanceBookingReport />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'terminal-booking-report',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'Auditor',
                    'AuditSupervisor',
                    'SuperAdmin',
                    'Administrator',
                    'GroupAccountant',
                    'FleetManager',
                    'IntelligentControlUnit',
                    'CustomerRepresentative',
                    'CustomerRepSupervisor',
                    'OperationHead',
                    'OperationsManager',
                    'RegionalManager',
                    'TerminalAccountant',
                  ]}
                >
                  <TerminalBookingReport />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'reschedule-reroute-report',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'Auditor',
                    'AuditSupervisor',
                    'SuperAdmin',
                    'Administrator',
                    'GroupAccountant',
                    'FleetManager',
                    'IntelligentControlUnit',
                    'CustomerRepresentative',
                    'CustomerRepSupervisor',
                    'OperationHead',
                    'OperationsManager',
                    'TerminalAccountant',
                  ]}
                >
                  <RescheduleRerouteReport />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'shortage-report',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'Auditor',
                    'AuditSupervisor',
                    'SuperAdmin',
                    'Administrator',
                    'GroupAccountant',
                    'FleetManager',
                    'IntelligentControlUnit',
                    'CustomerRepresentative',
                    'CustomerRepSupervisor',
                    'OperationHead',
                    'OperationsManager',
                    'TerminalAccountant',
                  ]}
                >
                  <ShortageReport />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'pickup-report',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'Auditor',
                    'AuditSupervisor',
                    'SuperAdmin',
                    'Administrator',
                    'IntelligentControlUnit',
                    'CustomerRepresentative',
                    'CustomerRepSupervisor',
                    'OperationHead',
                    'OnlineBookingRepresentative',
                    'OperationsManager',
                  ]}
                >
                  <PickupReport />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'terminal-booking-report-detail/:startDate/:endDate/:employeeEmail',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'Auditor',
                    'AuditSupervisor',
                    'SuperAdmin',
                    'Administrator',
                    'GroupAccountant',
                    'FleetManager',
                    'IntelligentControlUnit',
                    'CustomerRepresentative',
                    'CustomerRepSupervisor',
                    'OperationHead',
                    'OperationsManager',
                    'RegionalManager',
                    'TerminalAccountant',
                  ]}
                >
                  <TerminalBookingDetail />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'advance-booking-report-detail/:startDate/:endDate/:employeeEmail',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'Auditor',
                    'AuditSupervisor',
                    'SuperAdmin',
                    'Administrator',
                    'GroupAccountant',
                    'FleetManager',
                    'IntelligentControlUnit',
                    'CustomerRepresentative',
                    'CustomerRepSupervisor',
                    'OperationHead',
                    'OperationsManager',
                    'RegionalManager',
                    'TerminalAccountant',
                  ]}
                >
                  <AdvanceBookingDetail />{' '}
                </RoleBasedGuard>
              ),
            },
            // Journey Manager
            {
              path: 'extra-dispatch',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'Auditor',
                    'AuditSupervisor',
                    'SuperAdmin',
                    'Administrator',
                    'GroupAccountant',
                    'FleetManager',
                    'IntelligentControlUnit',
                    'CustomerRepresentative',
                    'CustomerRepSupervisor',
                    'OperationHead',
                    'OperationsManager',
                    'TerminalAccountant',
                  ]}
                >
                  <ExtraDispatch />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'out-going-fleet',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'Auditor',
                    'AuditSupervisor',
                    'SuperAdmin',
                    'Administrator',
                    'GroupAccountant',
                    'FleetManager',
                    'IntelligentControlUnit',
                    'CustomerRepresentative',
                    'CustomerRepSupervisor',
                    'OperationHead',
                    'OperationsManager',
                    'TerminalAccountant',
                    'RegionalManager',
                  ]}
                >
                  <OutGoingFleet />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'incoming-fleet',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'Auditor',
                    'AuditSupervisor',
                    'SuperAdmin',
                    'Administrator',
                    'GroupAccountant',
                    'FleetManager',
                    'IntelligentControlUnit',
                    'CustomerRepresentative',
                    'CustomerRepSupervisor',
                    'OperationHead',
                    'OperationsManager',
                    'TerminalAccountant',
                    'RegionalManager',
                  ]}
                >
                  <InComingFleet />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'manage-journey',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'Auditor',
                    'AuditSupervisor',
                    'SuperAdmin',
                    'Administrator',
                    'GroupAccountant',
                    'FleetManager',
                    'IntelligentControlUnit',
                    'CustomerRepresentative',
                    'CustomerRepSupervisor',
                    'OperationHead',
                    'OperationsManager',
                    'TerminalAccountant',
                    'RegionalManager',
                  ]}
                >
                  <ManageJourney />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'manage-journey/:vehicleRegId/:journeyMgtId',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'Auditor',
                    'AuditSupervisor',
                    'SuperAdmin',
                    'Administrator',
                    'GroupAccountant',
                    'FleetManager',
                    'IntelligentControlUnit',
                    'CustomerRepresentative',
                    'CustomerRepSupervisor',
                    'OperationHead',
                    'OperationsManager',
                    'TerminalAccountant',
                    'RegionalManager',
                  ]}
                >
                  <ManageJourneyInfo />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'transload-journey/:vehicleRegId/:journeyMgtId',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'Auditor',
                    'AuditSupervisor',
                    'SuperAdmin',
                    'Administrator',
                    'GroupAccountant',
                    'FleetManager',
                    'IntelligentControlUnit',
                    'CustomerRepresentative',
                    'CustomerRepSupervisor',
                    'OperationHead',
                    'OperationsManager',
                    'TerminalAccountant',
                    'RegionalManager',
                  ]}
                >
                  <TransloadJourney />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'incomplete-journey',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'Auditor',
                    'AuditSupervisor',
                    'SuperAdmin',
                    'Administrator',
                    'GroupAccountant',
                    'FleetManager',
                    'IntelligentControlUnit',
                    'CustomerRepresentative',
                    'CustomerRepSupervisor',
                    'OperationHead',
                    'OperationsManager',
                    'TerminalAccountant',
                    'RegionalManager',
                  ]}
                >
                  <IncompleteJourney />{' '}
                </RoleBasedGuard>
              ),
            },
          ],
        },

        // OPERATIONS /////////
        // //////////////////
        {
          path: 'operations',
          children: [
            { element: <Navigate to="/dashboard/operations/ost-final-report" replace />, index: true },
            {
              path: 'otp',
              element: (
                <RoleBasedGuard hasContent roles={['SuperAdmin', 'Administrator', 'RegionalManager']}>
                  <GenerateOtp />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'remove-refCode',
              element: (
                <RoleBasedGuard hasContent roles={['SuperAdmin', 'Administrator', 'RegionalManager']}>
                  <RemoveRefCode />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'final-report',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={['SuperAdmin', 'Administrator', 'OperationHead', 'OperationsManager', 'RegionalManager']}
                >
                  <OSTFinalReport />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'yesterday-terminal-final',
              element: (
                <RoleBasedGuard hasContent roles={['SuperAdmin', 'Administrator', 'OperationHead', 'RegionalManager']}>
                  <YesterdayTerminalFinal />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'vehicle-not-accounted-for',
              element: (
                <RoleBasedGuard hasContent roles={['SuperAdmin', 'Administrator', 'OperationHead', 'RegionalManager']}>
                  <VehicleNotAccountedFor />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'vehicle-not-in-end-of-today',
              element: (
                <RoleBasedGuard hasContent roles={['SuperAdmin', 'Administrator', 'OperationHead', 'RegionalManager']}>
                  <VehicleNotInEndOfToday />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'startup-report',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={['SuperAdmin', 'Administrator', 'OperationHead', 'OperationsManager', 'RegionalManager']}
                >
                  <OSTStartupReport />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'today-terminal-startup',
              element: (
                <RoleBasedGuard hasContent roles={['SuperAdmin', 'Administrator', 'OperationHead', 'RegionalManager']}>
                  <TodayTerminalStartup />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'vehicle-not-at-terminal',
              element: (
                <RoleBasedGuard hasContent roles={['SuperAdmin', 'Administrator', 'OperationHead', 'RegionalManager']}>
                  <VehicleNotAtTerminal />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'departure-report',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={['SuperAdmin', 'Administrator', 'HeadOfCustomerService', 'RegionalManager']}
                >
                  <DepartureReport />{' '}
                </RoleBasedGuard>
              ),
            },
          ],
        },

        // FLEETS /////////
        // //////////////////

        {
          path: 'fleet',
          children: [
            { element: <Navigate to="/dashboard/fleet/fleet-history" replace />, index: true },
            {
              path: 'vehicle',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'GroupAccountant',
                    'AuditSupervisor',
                    'Auditor',
                    'SuperAdmin',
                    'Administrator',
                    'FleetManager',
                    'Captain Relation Officer',
                    'Captain Relation Manager',
                    'OperationHead',
                    'OperationsManager',
                    'InventoryAdmin',
                    'InventoryOfficer',
                    'InventorySupervisor',
                    'RegionalManager',
                    'WorkshopSupervisor',
                    'RegionalManager',
                  ]}
                >
                  <VehicleSettings />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'fleet-history',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'SuperAdmin',
                    'Administrator',
                    'Auditor',
                    'AuditSupervisor',
                    'CustomerRepresentative',
                    'CustomerRepSupervisor',
                    'FleetManager',
                    'GroupAccountant',
                    'HumanResource',
                    'IntelligentControlUnit',
                    'InventoryAdmin',
                    'InventoryOfficer',
                    'InventorySupervisor',
                    'OnlineBookingRepresentative',
                    'Partner',
                    'PatrolTeamMember',
                    'ReportViewer',
                    'TerminalAccountant',
                    'OperationHead',
                    'OperationsManager',
                    'TerminalOfficer',
                    'Workshop',
                    'WorkshopSupervisor',
                    'WorkshopAdministrator',
                    'Ticketer',
                    'OnlineBookingManager',
                    'RegionalManager',
                    'EnterpriseBusinessManager',
                    'Marketing&Innovation',
                    'GLA',
                    'IT Support',
                    'ThirdPartyManager',
                    'LineManager',
                    'Captain Relation Officer',
                    'Captain Relation Manager',
                    'PartnerRelationsManager',
                    'DashboardReportsAccess',
                    'MarketingDashboardReportsAccess',
                    'ProcurementOfficer',
                    'ChiefProcurementOfficer',
                    'CEO',
                  ]}
                >
                  <FleetHistory />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'fleet-movement-chart',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'GroupAccountant',
                    'AuditSupervisor',
                    'Auditor',
                    'SuperAdmin',
                    'Administrator',
                    'FleetManager',
                    'Captain Relation Officer',
                    'Captain Relation Manager',
                    'InventoryAdmin',
                    'InventoryOfficer',
                    'InventorySupervisor',
                    'OperationsManager',
                    'RegionalManager',
                  ]}
                >
                  <FleetMovementChart />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'fleet-usage-report',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'GroupAccountant',
                    'AuditSupervisor',
                    'Auditor',
                    'SuperAdmin',
                    'Administrator',
                    'FleetManager',
                    'Captain Relation Officer',
                    'Captain Relation Manager',
                  ]}
                >
                  <FleetUsageReport />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'fleet-usage-report/:id/:type',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'GroupAccountant',
                    'AuditSupervisor',
                    'Auditor',
                    'SuperAdmin',
                    'Administrator',
                    'FleetManager',
                    'Captain Relation Officer',
                    'Captain Relation Manager',
                  ]}
                >
                  <FleetUsageReportDetails />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'fleet-movement/:id',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'GroupAccountant',
                    'AuditSupervisor',
                    'Auditor',
                    'SuperAdmin',
                    'Administrator',
                    'FleetManager',
                    'Captain Relation Officer',
                    'Captain Relation Manager',
                    'InventoryAdmin',
                    'InventoryOfficer',
                    'InventorySupervisor',
                    'OperationsManager',
                    'RegionalManager',
                  ]}
                >
                  <FleetMovement />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'fleet-movement-details/:terminalId/:type',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'GroupAccountant',
                    'AuditSupervisor',
                    'Auditor',
                    'SuperAdmin',
                    'Administrator',
                    'FleetManager',
                    'Captain Relation Officer',
                    'Captain Relation Manager',
                    'InventoryAdmin',
                    'InventoryOfficer',
                    'InventorySupervisor',
                    'OperationsManager',
                    'RegionalManager',
                  ]}
                >
                  <FleetMovementDetails />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'ops-fleet-terminal-usage/:id',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'GroupAccountant',
                    'AuditSupervisor',
                    'Auditor',
                    'SuperAdmin',
                    'Administrator',
                    'FleetManager',
                    'Captain Relation Officer',
                    'Captain Relation Manager',
                    'InventoryAdmin',
                    'InventoryOfficer',
                    'InventorySupervisor',
                    'TerminalAccountant',
                  ]}
                >
                  <OpsFleetTerminalUsage />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'ops-fleet-terminal-usage/:terminalId/:type',
              element: (
                <RoleBasedGuard hasContent roles={['SuperAdmin', 'Administrator']}>
                  <OpsFleetDetails />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'blown-fleet-history',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'GroupAccountant',
                    'AuditSupervisor',
                    'Auditor',
                    'SuperAdmin',
                    'Administrator',
                    'FleetManager',
                    'Captain Relation Officer',
                    'Captain Relation Manager',
                    'TerminalAccountant',
                    'InventoryAdmin',
                    'InventoryOfficer',
                    'InventorySupervisor',
                  ]}
                >
                  <BlownFleetHistory />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'fleet-financial',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'GroupAccountant',
                    'AuditSupervisor',
                    'Auditor',
                    'SuperAdmin',
                    'Administrator',
                    'FleetManager',
                    'Captain Relation Officer',
                    'Captain Relation Manager',
                  ]}
                >
                  <FleetFinancial />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'all-fleet-financial-details',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'GroupAccountant',
                    'AuditSupervisor',
                    'Auditor',
                    'SuperAdmin',
                    'Administrator',
                    'FleetManager',
                    'Captain Relation Officer',
                    'Captain Relation Manager',
                  ]}
                >
                  <FleetFinancialDetails />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'fleet-financial-details',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'GroupAccountant',
                    'AuditSupervisor',
                    'Auditor',
                    'SuperAdmin',
                    'Administrator',
                    'FleetManager',
                    'Captain Relation Officer',
                    'Captain Relation Manager',
                  ]}
                >
                  <VehicleReport />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'ops-fleet-history',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'GroupAccountant',
                    'AuditSupervisor',
                    'Auditor',
                    'SuperAdmin',
                    'Administrator',
                    'FleetManager',
                    'Captain Relation Officer',
                    'Captain Relation Manager',
                  ]}
                >
                  <OpsFleetHistory />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'ops-fleet-usage-summary',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'GroupAccountant',
                    'AuditSupervisor',
                    'Auditor',
                    'SuperAdmin',
                    'Administrator',
                    'FleetManager',
                    'Captain Relation Officer',
                    'Captain Relation Manager',
                    'TerminalAccountant',
                  ]}
                >
                  <OpsFleetUsageSummary />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'shared-revenue',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'GroupAccountant',
                    'AuditSupervisor',
                    'Auditor',
                    'SuperAdmin',
                    'Administrator',
                    'FleetManager',
                    'Captain Relation Officer',
                    'Captain Relation Manager',
                  ]}
                >
                  <SharedRevenue />{' '}
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: 'maintenance',
          children: [
            { element: <Navigate to="/dashboard/maintenance/operations-target" replace />, index: true },
            {
              path: 'operations-target',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'Workshop',
                    'WorkshopSupervisor',
                    'WorkshopAdministrator',
                    'Auditor',
                    'AuditSupervisor',
                    'GroupAccountant',
                    'SuperAdmin',
                    'Administrator',
                    'TerminalAccountant',
                    'OperationHead',
                    'OperationsManager',
                    'InventoryAdmin',
                    'InventoryOfficer',
                    'InventorySupervisor',
                  ]}
                >
                  <OperationsTarget />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'maintenance-home',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'Workshop',
                    'WorkshopSupervisor',
                    'WorkshopAdministrator',
                    'Auditor',
                    'AuditSupervisor',
                    'GroupAccountant',
                    'SuperAdmin',
                    'Administrator',
                    'TerminalAccountant',
                    'OperationHead',
                    'OperationsManager',
                    'InventoryAdmin',
                    'InventoryOfficer',
                    'InventorySupervisor',
                  ]}
                >
                  <MaintenanceHome />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'request-repair',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'Auditor',
                    'AuditSupervisor',
                    'GroupAccountant',
                    'SuperAdmin',
                    'Administrator',
                    'OperationHead',
                    'OperationsManager',
                    'Workshop',
                    'WorkshopSupervisor',
                    'WorkshopAdministrator',
                  ]}
                >
                  <RequestRepair />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'jobcard-request',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'OperationsManager',
                    'Workshop',
                    'WorkshopSupervisor',
                    'WorkshopAdministrator',
                    'SuperAdmin',
                    'Administrator',
                    'Auditor',
                    'AuditSupervisor',
                  ]}
                >
                  <JobcardRequest />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'jobcard-management',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'Auditor',
                    'AuditSupervisor',
                    'GroupAccountant',
                    'SuperAdmin',
                    'Administrator',
                    'InventoryOfficer',
                    'InventorySupervisor',
                    'InventoryAdmin',
                    'TerminalAccountant',
                    'OperationHead',
                    'OperationsManager',
                  ]}
                >
                  <JobcardManagement />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'open-service-vehicle',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'Auditor',
                    'AuditSupervisor',
                    'GroupAccountant',
                    'SuperAdmin',
                    'Administrator',
                    'InventoryOfficer',
                    'InventorySupervisor',
                    'InventoryAdmin',
                    'TerminalAccountant',
                    'OperationHead',
                  ]}
                >
                  <OpenServiceVehicle />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'maintenance-report',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'Auditor',
                    'AuditSupervisor',
                    'GroupAccountant',
                    'SuperAdmin',
                    'Administrator',
                    'InventoryOfficer',
                    'TerminalAccountant',
                    'InventoryAdmin',
                    'InventoryOfficer',
                    'InventorySupervisor',
                    'WorkshopSupervisor',
                    'OperationHead',
                  ]}
                >
                  <MaintenanceReport />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'closed-jobcard-report',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'Auditor',
                    'AuditSupervisor',
                    'GroupAccountant',
                    'SuperAdmin',
                    'Administrator',
                    'InventoryOfficer',
                    'TerminalAccountant',
                    'InventoryAdmin',
                    'InventoryOfficer',
                    'InventorySupervisor',
                  ]}
                >
                  <ClosedJobcardReport />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'cancelled-jobcards',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'Auditor',
                    'AuditSupervisor',
                    'GroupAccountant',
                    'SuperAdmin',
                    'Administrator',
                    'InventoryOfficer',
                    'TerminalAccountant',
                    'InventoryAdmin',
                    'InventoryOfficer',
                    'InventorySupervisor',
                  ]}
                >
                  <CancelledJobcards />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'jobcard-status-report',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'Auditor',
                    'AuditSupervisor',
                    'GroupAccountant',
                    'SuperAdmin',
                    'Administrator',
                    'InventoryOfficer',
                    'TerminalAccountant',
                    'InventoryAdmin',
                    'InventoryOfficer',
                    'InventorySupervisor',
                  ]}
                >
                  <JobcardStatusReport />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'jobcard-status-report:id',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'Auditor',
                    'AuditSupervisor',
                    'GroupAccountant',
                    'SuperAdmin',
                    'Administrator',
                    'InventoryOfficer',
                    'TerminalAccountant',
                    'InventoryAdmin',
                    'InventoryOfficer',
                    'InventorySupervisor',
                  ]}
                >
                  <JobcardStatusDetail />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'vehicle-with-jobcards',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'OperationsManager',
                    'Workshop',
                    'WorkshopSupervisor',
                    'WorkshopAdministrator',
                    'SuperAdmin',
                    'Administrator',
                    'Auditor',
                    'AuditSupervisor',
                  ]}
                >
                  <VehicleWithJobcards />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'captain-maintenance-costs',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'SuperAdmin',
                    'Administrator',
                    'Auditor',
                    'AuditSupervisor',
                    'GroupAccountant',
                    'Captain Relation Officer',
                    'Captain Relation Manager',
                  ]}
                >
                  <CaptainMaintenanceCosts />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'tyre-management',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'OperationsManager',
                    'Workshop',
                    'WorkshopSupervisor',
                    'WorkshopAdministrator',
                    'SuperAdmin',
                    'Administrator',
                    'Auditor',
                    'AuditSupervisor',
                  ]}
                >
                  <TyreManagement />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'tyre-swap',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'OperationsManager',
                    'Workshop',
                    'WorkshopSupervisor',
                    'WorkshopAdministrator',
                    'SuperAdmin',
                    'Administrator',
                    'Auditor',
                    'AuditSupervisor',
                  ]}
                >
                  <TyreSwap />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'tyre-movement',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'OperationsManager',
                    'Workshop',
                    'WorkshopSupervisor',
                    'WorkshopAdministrator',
                    'SuperAdmin',
                    'Administrator',
                    'Auditor',
                    'AuditSupervisor',
                  ]}
                >
                  <TyreMovement />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'tyre-report',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'OperationsManager',
                    'Workshop',
                    'WorkshopSupervisor',
                    'WorkshopAdministrator',
                    'SuperAdmin',
                    'Administrator',
                    'Auditor',
                    'AuditSupervisor',
                  ]}
                >
                  <TyreReport />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'mileage-report',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'Workshop',
                    'WorkshopSupervisor',
                    'WorkshopAdministrator',
                    'SuperAdmin',
                    'Administrator',
                    'Auditor',
                    'AuditSupervisor',
                  ]}
                >
                  <MileageReport />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'schedule-maintenance-queue',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'Workshop',
                    'WorkshopSupervisor',
                    'WorkshopAdministrator',
                    'SuperAdmin',
                    'Administrator',
                    'Auditor',
                    'AuditSupervisor',
                    'WorkshopSupervisor',
                  ]}
                >
                  <ScheduleMaintenanceQueue />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'idle-vehicle',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'Auditor',
                    'AuditSupervisor',
                    'GroupAccountant',
                    'SuperAdmin',
                    'Administrator',
                    'InventorySupervisor',
                    'WorkshopSupervisor',
                  ]}
                >
                  <IdleVehicle />{' '}
                </RoleBasedGuard>
              ),
            },

            // Maintenace Settings
            {
              path: 'maintenance-part-Type',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'SuperAdmin',
                    'Administrator',
                    'AuditSupervisor',
                    'WorkshopSupervisor',
                    'WorkshopAdministrator',
                  ]}
                >
                  <MaintenancePartTypeSettings />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'workshop',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'SuperAdmin',
                    'Administrator',
                    'AuditSupervisor',
                    'WorkshopSupervisor',
                    'WorkshopAdministrator',
                  ]}
                >
                  <MaintenanceWorkshop />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'jobcard-categories',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'SuperAdmin',
                    'Administrator',
                    'AuditSupervisor',
                    'WorkshopSupervisor',
                    'WorkshopAdministrator',
                  ]}
                >
                  <JobcardCategory />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'scheduled-maintenance-mileage',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'SuperAdmin',
                    'Administrator',
                    'AuditSupervisor',
                    'WorkshopSupervisor',
                    'WorkshopAdministrator',
                  ]}
                >
                  <ScheduledMaintenanceMileage />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'vehicle-due-for-service',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'SuperAdmin',
                    'Administrator',
                    'AuditSupervisor',
                    'WorkshopSupervisor',
                    'WorkshopAdministrator',
                    'OperationsManager',
                  ]}
                >
                  <VehicleDueForService />{' '}
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: 'hire-service',
          children: [
            { element: <Navigate to="/dashboard/hire-service/hire-booking" replace />, index: true },
            {
              path: 'hire-booking',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'Auditor',
                    'AuditSupervisor',
                    'SuperAdmin',
                    'Administrator',
                    'OnlineBookingManager',
                    'FleetManager',
                    'GroupAccountant',
                    'CustomerRepresentative',
                    'CustomerRepSupervisor',
                    'OperationsManager',
                  ]}
                >
                  <HireBooking />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'assign-bus',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'Auditor',
                    'AuditSupervisor',
                    'SuperAdmin',
                    'Administrator',
                    'OnlineBookingManager',
                    'FleetManager',
                    'GroupAccountant',
                    'CustomerRepresentative',
                    'OperationsManager',
                    'RegionalManager',
                  ]}
                >
                  <AssignBus />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'assign-bus-by-id/:id',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'Auditor',
                    'AuditSupervisor',
                    'SuperAdmin',
                    'Administrator',
                    'OnlineBookingManager',
                    'FleetManager',
                    'GroupAccountant',
                    'CustomerRepresentative',
                    'OperationsManager',
                    'RegionalManager',
                  ]}
                >
                  <AssignBusById />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'hire-service-request/:id/:hiredBookingId/:vehModelId',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'Auditor',
                    'AuditSupervisor',
                    'SuperAdmin',
                    'Administrator',
                    'OnlineBookingManager',
                    'FleetManager',
                    'GroupAccountant',
                    'CustomerRepresentative',
                    'OperationsManager',
                    'TerminalAccountant',
                  ]}
                >
                  <HireServiceRequest />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'assigned-vehicle',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'Auditor',
                    'AuditSupervisor',
                    'SuperAdmin',
                    'Administrator',
                    'OnlineBookingManager',
                    'FleetManager',
                    'GroupAccountant',
                    'CustomerRepresentative',
                    'OperationsManager',
                    'RegionalManager',
                  ]}
                >
                  <AssignedVehicle />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'hire-service-report',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'Auditor',
                    'AuditSupervisor',
                    'SuperAdmin',
                    'Administrator',
                    'OnlineBookingManager',
                    'FleetManager',
                    'GroupAccountant',
                    'CustomerRepresentative',
                    'CustomerRepSupervisor',
                    'OperationsManager',
                    'TerminalAccountant',
                  ]}
                >
                  <HireServiceReport />{' '}
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: 'procurement',
          children: [
            { element: <Navigate to="/dashboard/procurement/general-items" replace />, index: true },
            {
              path: 'general-items',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'Auditor',
                    'AuditSupervisor',
                    'GroupAccountant',
                    'SuperAdmin',
                    'Administrator',
                    'ProcurementOfficer',
                    'ChiefProcurementOfficer',
                  ]}
                >
                  <GeneralItems />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'inventory-requests',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'Auditor',
                    'AuditSupervisor',
                    'GroupAccountant',
                    'SuperAdmin',
                    'Administrator',
                    'ProcurementOfficer',
                    'ChiefProcurementOfficer',
                  ]}
                >
                  <InventoryRequests />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'request-orders',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'AuditSupervisor',
                    'SuperAdmin',
                    'Administrator',
                    'ChiefProcurementOfficer',
                    'ProcurementOfficer',
                    'InventoryAdmin',
                    'InventoryOfficer',
                    'InventorySupervisor',
                    'OperationsManager',
                  ]}
                >
                  <RequestOrders />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'purchased-orders',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'AuditSupervisor',
                    'GroupAccountant',
                    'SuperAdmin',
                    'Administrator',
                    'ChiefProcurementOfficer',
                    'ProcurementOfficer',
                  ]}
                >
                  <PurchasedOrders />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'purchased-history',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'Auditor',
                    'AuditSupervisor',
                    'GroupAccountant',
                    'SuperAdmin',
                    'Administrator',
                    'ProcurementOfficer',
                    'ChiefProcurementOfficer',
                    'Workshop',
                    'WorkshopSupervisor',
                    'WorkshopAdministrator',
                  ]}
                >
                  <PurchasedHistory />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'general-requests',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'Auditor',
                    'AuditSupervisor',
                    'GroupAccountant',
                    'SuperAdmin',
                    'Administrator',
                    'ProcurementOfficer',
                    'ChiefProcurementOfficer',
                    'InventoryAdmin',
                    'InventoryOfficer',
                    'InventorySupervisor',
                    'OperationHead',
                    'OperationsManager',
                  ]}
                >
                  <GeneralRequests />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'process-general-requests',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={['SuperAdmin', 'Administrator', 'ChiefProcurementOfficer', 'ProcurementOfficer']}
                >
                  <ProcessGeneralRequests />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'process-general-requests-detail/:id',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={['SuperAdmin', 'Administrator', 'ChiefProcurementOfficer', 'ProcurementOfficer']}
                >
                  <ProcessGeneralRequestDetail />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'edit-process-general-requests/:id',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'Auditor',
                    'AuditSupervisor',
                    'GroupAccountant',
                    'SuperAdmin',
                    'Administrator',
                    'ChiefProcurementOfficer',
                    'ProcurementOfficer',
                  ]}
                >
                  <EditProcessRequest />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'general-requests-items',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'Auditor',
                    'AuditSupervisor',
                    'GroupAccountant',
                    'SuperAdmin',
                    'Administrator',
                    'ProcurementOfficer',
                    'ChiefProcurementOfficer',
                    'OperationsManager',
                  ]}
                >
                  <GeneralRequestItems />{' '}
                </RoleBasedGuard>
              ),
            },

            {
              path: 'purchased-orders-detail/:id',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'AuditSupervisor',
                    'GroupAccountant',
                    'SuperAdmin',
                    'Administrator',
                    'ChiefProcurementOfficer',
                    'ProcurementOfficer',
                  ]}
                >
                  <PurchaseOrderDetail />{' '}
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: 'inventory',
          children: [
            { element: <Navigate to="/dashboard/inventory/item-request" replace />, index: true },
            {
              path: 'item-request',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'Auditor',
                    'AuditSupervisor',
                    'GroupAccountant',
                    'SuperAdmin',
                    'Administrator',
                    'ProcurementOfficer',
                    'ChiefProcurementOfficer',
                    'Workshop',
                    'WorkshopSupervisor',
                    'WorkshopAdministrator',
                    'InventoryAdmin',
                    'InventoryOfficer',
                    'InventorySupervisor',
                  ]}
                >
                  <InventoryRequestItems />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'procument-items',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'GroupAccountant',
                    'Auditor',
                    'AuditSupervisor',
                    'SuperAdmin',
                    'Administrator',
                    'InventoryOfficer',
                    'InventorySupervisor',
                    'InventoryAdmin',
                  ]}
                >
                  <InventoryProcumentItems />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'procument-items-detail/:id',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'GroupAccountant',
                    'Auditor',
                    'AuditSupervisor',
                    'SuperAdmin',
                    'Administrator',
                    'InventoryOfficer',
                    'InventorySupervisor',
                    'InventoryAdmin',
                  ]}
                >
                  <InventoryProcumentItemDetail />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'request-item',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'GroupAccountant',
                    'Auditor',
                    'AuditSupervisor',
                    'SuperAdmin',
                    'Administrator',
                    'InventoryAdmin',
                    'InventoryOfficer',
                    'InventorySupervisor',
                  ]}
                >
                  <InventoryInternalRequestItems />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'approve-stock-request',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'GroupAccountant',
                    'Auditor',
                    'AuditSupervisor',
                    'SuperAdmin',
                    'Administrator',
                    'InventoryAdmin',
                    'InventoryOfficer',
                    'InventorySupervisor',
                    'PatrolTeamMember',
                  ]}
                >
                  <ApproveStockRequest />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'sent-items',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'GroupAccountant',
                    'Auditor',
                    'AuditSupervisor',
                    'SuperAdmin',
                    'Administrator',
                    'InventoryOfficer',
                    'InventorySupervisor',
                    'PatrolTeamMember',
                    'InventoryAdmin',
                  ]}
                >
                  <InventorySentItems />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'receive-items',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'GroupAccountant',
                    'Auditor',
                    'AuditSupervisor',
                    'SuperAdmin',
                    'Administrator',
                    'InventoryOfficer',
                    'InventorySupervisor',
                    'PatrolTeamMember',
                    'InventoryAdmin',
                  ]}
                >
                  <InventoryReceiveItems />{' '}
                </RoleBasedGuard>
              ),
            },

            {
              path: 'stores',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'SuperAdmin',
                    'Administrator',
                    'AuditSupervisor',
                    'InventoryAdmin',
                    'InventoryOfficer',
                    'InventorySupervisor',
                  ]}
                >
                  <InventoryStoreSettings />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'vehicle-part',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'SuperAdmin',
                    'Administrator',
                    'AuditSupervisor',
                    'InventoryAdmin',
                    'InventoryOfficer',
                    'InventorySupervisor',
                  ]}
                >
                  <InventoryVehiclePartSettings />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'vehicle-part-position',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'SuperAdmin',
                    'Administrator',
                    'AuditSupervisor',
                    'InventoryAdmin',
                    'InventoryOfficer',
                    'InventorySupervisor',
                  ]}
                >
                  <VehiclePartPositionSettings />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'jobcard-items',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'SuperAdmin',
                    'Administrator',
                    'AuditSupervisor',
                    'InventoryAdmin',
                    'InventoryOfficer',
                    'InventorySupervisor',
                  ]}
                >
                  <JobCardItems />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'tyre',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'SuperAdmin',
                    'Administrator',
                    'AuditSupervisor',
                    'InventoryAdmin',
                    'InventoryOfficer',
                    'InventorySupervisor',
                  ]}
                >
                  <TyreSetupSettings />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'vehicle-tyre',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'SuperAdmin',
                    'Administrator',
                    'AuditSupervisor',
                    'InventoryAdmin',
                    'InventoryOfficer',
                    'InventorySupervisor',
                  ]}
                >
                  <VehicleTyreSetupSettings />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'vendor',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'SuperAdmin',
                    'Administrator',
                    'AuditSupervisor',
                    'InventoryAdmin',
                    'InventoryOfficer',
                    'InventorySupervisor',
                    'ProcurementOfficer',
                    'ChiefProcurementOfficer',
                  ]}
                >
                  <VendorSettings />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'history',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'GroupAccountant',
                    'Auditor',
                    'AuditSupervisor',
                    'SuperAdmin',
                    'Administrator',
                    'InventoryOfficer',
                    'InventorySupervisor',
                    'InventoryAdmin',
                  ]}
                >
                  <InventoryHistory />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'stock',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'GroupAccountant',
                    'InventorySupervisor',
                    'PatrolTeamMember',
                    'InventoryOfficer',
                    'InventoryAdmin',
                    'SuperAdmin',
                    'Administrator',
                    'Auditor',
                    'AuditSupervisor',
                  ]}
                >
                  <InventoryReport />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'general-stock',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'GroupAccountant',
                    'Auditor',
                    'AuditSupervisor',
                    'SuperAdmin',
                    'Administrator',
                    'InventoryOfficer',
                    'InventorySupervisor',
                    'InventoryAdmin',
                  ]}
                >
                  <InventoryGeneralStock />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'store-details/:id',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'GroupAccountant',
                    'Auditor',
                    'AuditSupervisor',
                    'SuperAdmin',
                    'Administrator',
                    'InventoryOfficer',
                    'InventorySupervisor',
                    'InventoryAdmin',
                  ]}
                >
                  <InventoryStock />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'raise-purchase-order',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'GroupAccountant',
                    'Auditor',
                    'AuditSupervisor',
                    'SuperAdmin',
                    'Administrator',
                    'InventoryOfficer',
                    'InventorySupervisor',
                    'InventoryAdmin',
                  ]}
                >
                  <RaisePurchaseOrder />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'receive-supplied-items',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'GroupAccountant',
                    'Auditor',
                    'AuditSupervisor',
                    'SuperAdmin',
                    'Administrator',
                    'InventoryOfficer',
                    'InventorySupervisor',
                    'InventoryAdmin',
                  ]}
                >
                  <ReceiveSuppliedItems />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'purchased-orders-detail/:id',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'GroupAccountant',
                    'Auditor',
                    'AuditSupervisor',
                    'SuperAdmin',
                    'Administrator',
                    'InventoryOfficer',
                    'InventorySupervisor',
                    'InventoryAdmin',
                  ]}
                >
                  <ReceivePurchaseOrder />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'receive-supplied-item/:id',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'GroupAccountant',
                    'Auditor',
                    'AuditSupervisor',
                    'SuperAdmin',
                    'Administrator',
                    'InventoryOfficer',
                    'InventorySupervisor',
                    'InventoryAdmin',
                  ]}
                >
                  <ReceiveSuppliedOrder />{' '}
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/profile" replace />, index: true },
            {
              path: 'profile-info',
              element: (
                <RoleBasedGuard hasContent roles={['HumanResource', 'SuperAdmin', 'Administrator']}>
                  <GeneralBooking />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'cards',
              element: (
                <RoleBasedGuard hasContent roles={['HumanResource', 'SuperAdmin', 'Administrator']}>
                  <UserCards />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'list',
              element: (
                <RoleBasedGuard hasContent roles={['HumanResource', 'SuperAdmin', 'Administrator']}>
                  <UserList />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'new',
              element: (
                <RoleBasedGuard hasContent roles={['HumanResource', 'SuperAdmin', 'Administrator']}>
                  <UserCreate />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: ':email/edit',
              element: (
                <RoleBasedGuard hasContent roles={['HumanResource', 'SuperAdmin', 'Administrator']}>
                  <UserCreate />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'account',
              element: (
                <RoleBasedGuard hasContent roles={['HumanResource', 'SuperAdmin', 'Administrator']}>
                  <UserAccount />{' '}
                </RoleBasedGuard>
              ),
            },
          ],
        },
      ],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        {
          path: 'track-bus/:t/:i',
          element: <TrackBusById />,
        },
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: 'pricing', element: <Pricing /> },
        { path: 'payment', element: <Payment /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: '/',
      element: <MainLayout />,
      children: [
        { path: 'about-us', element: <About /> },
        { path: 'contact-us', element: <Contact /> },
        { path: 'faqs', element: <Faqs /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const ForgotPassword = Loadable(lazy(() => import('../pages/auth/ForgotPassword')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));

// DASHBOARD

// GENERAL
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));
const CustomerSupport = Loadable(lazy(() => import('../pages/dashboard/customer-support/CustomerSupport')));
const PaymentReversal = Loadable(lazy(() => import('../pages/dashboard/Payment-Reversal/PaymentReversal')));
const ManageUserAccount = Loadable(lazy(() => import('../pages/dashboard/manage-employee/ManageUserAccount')));
const AuditLog = Loadable(lazy(() => import('../pages/dashboard/audit/AuditLog')));
const CustomerRecord = Loadable(lazy(() => import('../pages/dashboard/customer-record/CustomerRecord')));
const CustomerBookingHistory = Loadable(
  lazy(() => import('../pages/dashboard/customer-booking/CustomerBookingHistory'))
);
const CustomerWalletHistory = Loadable(lazy(() => import('../pages/dashboard/customer-wallet/CustomerWalletHistory')));
const CustomerBillPayment = Loadable(
  lazy(() => import('../pages/dashboard/customer-bills-payment/CustomerBillPayment'))
);
const GeneralBooking = Loadable(lazy(() => import('../pages/dashboard/GeneralBooking')));
const PaymentGateWay = Loadable(lazy(() => import('../pages/dashboard/bookings/payment-gateway/PaymentGateWay')));

// HIRE SERVICE
// ///////////////////////////
// /////////////////////////////////
const HireBooking = Loadable(lazy(() => import('../pages/dashboard/hire-service/HireBooking')));
const AssignBus = Loadable(lazy(() => import('../pages/dashboard/hire-service/AssignABus')));
const AssignBusById = Loadable(lazy(() => import('../pages/dashboard/hire-service/AssignBusById')));
const HireServiceRequest = Loadable(lazy(() => import('../pages/dashboard/hire-service/HireServiceRequest')));
const AssignedVehicle = Loadable(lazy(() => import('../pages/dashboard/hire-service/AssignedVehicle')));
const HireServiceReport = Loadable(lazy(() => import('../pages/dashboard/hire-service/HireServiceReport')));

// PROCUREMENT
// ///////////////////////////
// /////////////////////////////////
const GeneralItems = Loadable(lazy(() => import('../pages/dashboard/procurement/GeneralItems')));
const InventoryRequests = Loadable(lazy(() => import('../pages/dashboard/procurement/InventoryRequest')));
const RequestOrders = Loadable(lazy(() => import('../pages/dashboard/procurement/RequestOrders')));
const PurchasedOrders = Loadable(lazy(() => import('../pages/dashboard/procurement/PurchasedOrders')));
const PurchasedHistory = Loadable(lazy(() => import('../pages/dashboard/procurement/PurchasedHistory')));
const GeneralRequests = Loadable(lazy(() => import('../pages/dashboard/procurement/GeneralRequest')));
const ProcessGeneralRequests = Loadable(lazy(() => import('../pages/dashboard/procurement/ProcessGeneralRequest')));
const GeneralRequestItems = Loadable(lazy(() => import('../pages/dashboard/procurement/GeneralRequestItems')));
const ProcessGeneralRequestDetail = Loadable(
  lazy(() => import('../pages/dashboard/procurement/details/ProcessGeneralRequestDetail'))
);
const EditProcessRequest = Loadable(lazy(() => import('../pages/dashboard/procurement/forms/EditProcessRequest')));
const PurchaseOrderDetail = Loadable(lazy(() => import('../pages/dashboard/procurement/details/PurchaseOrderDetail')));

// BOOKINGS AND RESERVATION
// ///////////////////////////
// /////////////////////////////////
const AddPickUp = Loadable(lazy(() => import('../pages/dashboard/AddPickUp')));
const CombinedBus = Loadable(lazy(() => import('../pages/dashboard/combined-bus/CombinedBus')));
const CombinedBusTicket = Loadable(lazy(() => import('../pages/dashboard/combined-bus/CombinedBusTicket')));
const AdvanceBooking = Loadable(lazy(() => import('../pages/dashboard/AdvanceBooking')));
const AllTicketBookings = Loadable(lazy(() => import('../pages/dashboard/AllTicketBookings')));
const BookOnHold = Loadable(lazy(() => import('../pages/dashboard/BookOnHold')));
const BookedVehicleManifest = Loadable(lazy(() => import('../pages/dashboard/booked-vehicle/BookedVehicleManifest')));
const BookedVehicle = Loadable(lazy(() => import('../pages/dashboard/booked-vehicle/BookedVehicle')));
const TicketSalesRecords = Loadable(lazy(() => import('../pages/dashboard/ticket-sales-record/TicketSalesRecords')));

// const Customer = Loadable(lazy(() => import('../pages/dashboard/Customer')));
const MgtOnHoldBookings = Loadable(lazy(() => import('../pages/dashboard/bookings/mgtOnhold/MtgOnHoldBookings')));
const RouteBuses = Loadable(lazy(() => import('../pages/dashboard/RouteBuses')));
const BlownTicket = Loadable(lazy(() => import('../pages/dashboard/BlownTicket')));
const Ticket = Loadable(lazy(() => import('../pages/dashboard/Ticket')));
const RerouteBooking = Loadable(lazy(() => import('../pages/dashboard/RerouteBooking')));
const RescheduleBooking = Loadable(
  lazy(() => import('../sections/@dashboard/reservation/reshedule-ticket/RescheduleBooking'))
);
const AddSeatToTicket = Loadable(
  lazy(() => import('../sections/@dashboard/reservation/Add-seat-to-Ticket/AddSeatToTicket'))
);
const UpdatePaymentMethod = Loadable(
  lazy(() => import('../components/bookings/update-payment-method/UpdatePaymentMethod'))
);
const TemporalRouteBuses = Loadable(lazy(() => import('../pages/dashboard/TemporalRouteBuses')));
const TerminalListing = Loadable(lazy(() => import('../pages/dashboard/TerminalListing')));
const SwapVehicle = Loadable(lazy(() => import('../pages/dashboard/reservation/swap-vehicle/SwapVehicle')));
const SwapPassVehicle = Loadable(
  lazy(() => import('../pages/dashboard/reservation/swap-pass-vehicle/SwapPassVehicle'))
);
const BookingAnalysis = Loadable(lazy(() => import('../pages/dashboard/operations/booking-analysis/BookingAnalysis')));

const TerminalRoute = Loadable(lazy(() => import('../pages/dashboard/TerminalRoute')));
const BookingManager = Loadable(lazy(() => import('../pages/dashboard/BookingManager')));

// Create A Bus
const AssignBusToTrip = Loadable(lazy(() => import('../pages/dashboard/reservation/create-bus/AssignBusToTrip')));
const BlownBus = Loadable(lazy(() => import('../pages/dashboard/reservation/create-bus/BlownBus')));
const BlownBusRequest = Loadable(
  lazy(() => import('../pages/dashboard/reservation/create-bus/blown-bus-request/BlownBusRequest'))
);
const BlownBusReport = Loadable(lazy(() => import('../pages/dashboard/reservation/blown-bus-report/BlownBusReport')));
const BlownBusTicket = Loadable(lazy(() => import('../pages/dashboard/reservation/create-bus/BlownBusTicket')));
const RegisterLeftoverBus = Loadable(
  lazy(() => import('../pages/dashboard/reservation/create-bus/RegisterLeftoverBus'))
);
const VirtualBlownBus = Loadable(lazy(() => import('../pages/dashboard/reservation/create-bus/VirtualBlownBus')));
const VirtualBus = Loadable(lazy(() => import('../pages/dashboard/reservation/create-bus/VirtualBus')));

// Booking Report
const AdvanceBookingReport = Loadable(
  lazy(() => import('../pages/dashboard/reservation/advance-booking-report/AdvanceBookingReport'))
);
const TerminalBookingReport = Loadable(
  lazy(() => import('../pages/dashboard/reservation/terminal-bookings-report/TerminalBookingReport'))
);
const RescheduleRerouteReport = Loadable(
  lazy(() => import('../pages/dashboard/reservation/reschedule-reroute-report/RescheduleRerouteReport'))
);
const ShortageReport = Loadable(lazy(() => import('../pages/dashboard/reservation/shortage-report/ShortageReport')));

const TerminalBookingDetail = Loadable(
  lazy(() => import('../pages/dashboard/reservation/terminal-bookings-report/TerminalBookingDetail'))
);

const PickupReport = Loadable(lazy(() => import('../pages/dashboard/reservation/pickup-report/PickupReport')));

const AdvanceBookingDetail = Loadable(
  lazy(() => import('../pages/dashboard/reservation/advance-booking-report/AdvanceBookingDetail'))
);
const BookingChannelReport = Loadable(
  lazy(() => import('../pages/dashboard/bookings/booking-channel-report/BookingChannelReport'))
);

// Journey Manager
const ExtraDispatch = Loadable(lazy(() => import('../pages/dashboard/reservation/journey-manager/ExtraDispatch')));
const OutGoingFleet = Loadable(lazy(() => import('../pages/dashboard/reservation/journey-manager/OutGoingFleet')));
const InComingFleet = Loadable(lazy(() => import('../pages/dashboard/reservation/journey-manager/InComingFleet')));
const ManageJourney = Loadable(lazy(() => import('../pages/dashboard/reservation/journey-manager/ManageJourney')));
const ManageJourneyInfo = Loadable(
  lazy(() => import('../pages/dashboard/reservation/journey-manager/ManageJourneyInfo'))
);
const TransloadJourney = Loadable(
  lazy(() => import('../pages/dashboard/reservation/journey-manager/TransloadJourney'))
);
const IncompleteJourney = Loadable(
  lazy(() => import('../pages/dashboard/reservation/journey-manager/IncompleteJourney'))
);
const SharedRevenue = Loadable(lazy(() => import('../pages/dashboard/reservation/journey-manager/SharedRevenue')));
// FLEET MANAGEMENT
// ///////////////////
const FleetFinancial = Loadable(lazy(() => import('../pages/dashboard/bookings/fleet-managemnt/FleetFinancial')));
const FleetFinancialDetails = Loadable(
  lazy(() => import('../pages/dashboard/bookings/fleet-managemnt/FleetFinancialDetails'))
);
const VehicleReport = Loadable(lazy(() => import('../pages/dashboard/bookings/fleet-managemnt/VehicleReport')));
const FleetHistory = Loadable(lazy(() => import('../pages/dashboard/bookings/fleet-managemnt/FleetHistory')));
const FleetUsageReport = Loadable(
  lazy(() => import('../pages/dashboard/bookings/fleet-managemnt/fleet-usage-report/FleetUsageReport'))
);
const FleetUsageReportDetails = Loadable(
  lazy(() => import('../pages/dashboard/bookings/fleet-managemnt/fleet-usage-report/FleetUsageReportDetails'))
);
const EmployeeRoute = Loadable(lazy(() => import('../pages/dashboard/bookings/employee-route/EmployeeRoute')));
const FleetMovement = Loadable(lazy(() => import('../pages/dashboard/reservation/journey-manager/FleetMovement')));
const OpsFleetTerminalUsage = Loadable(
  lazy(() => import('../pages/dashboard/bookings/fleet-managemnt/ops-fleet-usage/OpsFleetTerminalUsage'))
);
const OpsFleetDetails = Loadable(
  lazy(() => import('../pages/dashboard/bookings/fleet-managemnt/ops-fleet-usage/OpsFleetDetails'))
);
const FleetMovementChart = Loadable(
  lazy(() => import('../pages/dashboard/reservation/journey-manager/FleetMovementChart'))
);
const FleetMovementDetails = Loadable(
  lazy(() => import('../pages/dashboard/reservation/journey-manager/FleetMovementDetails'))
);
const BlownFleetHistory = Loadable(
  lazy(() => import('../pages/dashboard/reservation/journey-manager/BlownFleetHistory'))
);
const OpsFleetHistory = Loadable(lazy(() => import('../pages/dashboard/reservation/journey-manager/OpsFleetHistory')));
const OpsFleetUsageSummary = Loadable(
  lazy(() => import('../pages/dashboard/bookings/fleet-managemnt/ops-fleet-usage/OpsFleetUsageSummary'))
);

// INVENTORY SETTINGS
const InventoryStoreSettings = Loadable(
  lazy(() => import('../pages/dashboard/inventory/inventory-store/InventoryStoreSettings'))
);
const InventoryVehiclePartSettings = Loadable(
  lazy(() => import('../pages/dashboard/inventory/inventory-vehicle-part/InventoryVehiclePartSettings'))
);
const VehiclePartPositionSettings = Loadable(
  lazy(() => import('../pages/dashboard/inventory/vehicle-part-position/VehiclePartPositionSettings'))
);
const JobCardItems = Loadable(lazy(() => import('../pages/dashboard/inventory/job-card-items/JobCardItems')));

const TyreSetupSettings = Loadable(lazy(() => import('../pages/dashboard/inventory/tyre/TyreSetupSettings')));

const VehicleTyreSetupSettings = Loadable(
  lazy(() => import('../pages/dashboard/inventory/assign-tyre-to-vehicle/VehicleTyreSetupSettings'))
);
const VendorSettings = Loadable(lazy(() => import('../pages/dashboard/inventory/vendor/VendorSettings')));

// MENTENACE SETTINGS
const MaintenancePartTypeSettings = Loadable(
  lazy(() => import('../pages/dashboard/maintenance-repair/maintenance-part-type/MaintenancePartTypeSettings'))
);
const MaintenanceWorkshop = Loadable(
  lazy(() => import('../pages/dashboard/maintenance-repair/maintenance-workshop/MaintenanceWorkshop'))
);

const JobcardCategory = Loadable(
  lazy(() => import('../pages/dashboard/maintenance-repair/maintenance-jobcard/JobcardCategory'))
);

// MENTENACE && REPAIR
const OperationsTarget = Loadable(lazy(() => import('../pages/dashboard/maintenance-repair/OperationsTarget')));
const MaintenanceHome = Loadable(
  lazy(() => import('../pages/dashboard/maintenance-repair/maintenace-home/MaintenanceHome'))
);
const RequestRepair = Loadable(
  lazy(() => import('../pages/dashboard/maintenance-repair/request-repair/RequestRepair'))
);
const JobcardRequest = Loadable(
  lazy(() => import('../pages/dashboard/maintenance-repair/receive-jobcard-request/JobcardRequest'))
);

const JobcardManagement = Loadable(lazy(() => import('../pages/dashboard/maintenance-repair/job-card-mgt/JobCardMgt')));
const OpenServiceVehicle = Loadable(
  lazy(() => import('../pages/dashboard/maintenance-repair/open-service-vehicle/OpenServiceVehicle'))
);

const VehicleDueForService = Loadable(
  lazy(() => import('../pages/dashboard/maintenance-repair/vehicle-due-for-service/VehicleDueForService'))
);

const ScheduledMaintenanceMileage = Loadable(
  lazy(() => import('../pages/dashboard/maintenance-repair/scheduled-maintenance-mileage/ScheduledMaintenanceMileage'))
);

const MaintenanceReport = Loadable(
  lazy(() => import('../pages/dashboard/maintenance-repair/maintenance-report/MaintenanceReport'))
);
const ClosedJobcardReport = Loadable(
  lazy(() => import('../pages/dashboard/maintenance-repair/closed-job-card/ClosedJobcardReport'))
);
const CancelledJobcards = Loadable(
  lazy(() => import('../pages/dashboard/maintenance-repair/cancelled-job-card/CancelledJobcards'))
);
const JobcardStatusReport = Loadable(
  lazy(() => import('../pages/dashboard/maintenance-repair/job-card-status/JobcardStatusReport'))
);
const JobcardStatusDetail = Loadable(
  lazy(() => import('../pages/dashboard/maintenance-repair/job-card-status/JobcardStatusDetail'))
);
const VehicleWithJobcards = Loadable(
  lazy(() => import('../pages/dashboard/maintenance-repair/vehicle-with-jobcard/VehicleWithJobcard'))
);
const CaptainMaintenanceCosts = Loadable(lazy(() => import('../pages/dashboard/maintenance-repair/OperationsTarget')));
const TyreManagement = Loadable(lazy(() => import('../pages/dashboard/maintenance-repair/tire-mgt/TireManagement')));
const TyreSwap = Loadable(lazy(() => import('../pages/dashboard/maintenance-repair/tire-swap/TireSwap')));
const TyreMovement = Loadable(lazy(() => import('../pages/dashboard/maintenance-repair/OperationsTarget')));
const TyreReport = Loadable(lazy(() => import('../pages/dashboard/maintenance-repair/OperationsTarget')));
const MileageReport = Loadable(lazy(() => import('../pages/dashboard/maintenance-repair/OperationsTarget')));
const ScheduleMaintenanceQueue = Loadable(
  lazy(() => import('../pages/dashboard/maintenance-repair/schedule-maintenance/ScheduleMaintenanceQueue'))
);
const IdleVehicle = Loadable(lazy(() => import('../pages/dashboard/maintenance-repair/idle-vehicle/IdleVehicle')));

// INVENTORY
const InventoryRequestItems = Loadable(
  lazy(() => import('../pages/dashboard/inventory/procument/item-request/InventoryRequestItems'))
);
const InventoryProcumentItems = Loadable(
  lazy(() => import('../pages/dashboard/inventory/procument/procument-items/InventoryProcumentItems'))
);
const InventoryProcumentItemDetail = Loadable(
  lazy(() => import('../pages/dashboard/inventory/procument/procument-items/InventoryProcumentItemDetail'))
);
const InventoryInternalRequestItems = Loadable(
  lazy(() => import('../pages/dashboard/inventory/request-item/InventoryInternalRequestItems'))
);
const ApproveStockRequest = Loadable(
  lazy(() => import('../pages/dashboard/inventory/approve-stock-request/ApproveStockRequest'))
);
const InventorySentItems = Loadable(lazy(() => import('../pages/dashboard/inventory/sent-items/InventorySentItems')));
const InventoryReceiveItems = Loadable(
  lazy(() => import('../pages/dashboard/inventory/receive-items/InventoryReceiveItems'))
);
const InventoryHistory = Loadable(
  lazy(() => import('../pages/dashboard/inventory/inventory-history/InventoryHistory'))
);
const InventoryReport = Loadable(lazy(() => import('../pages/dashboard/inventory/inventory-report/InventoryReport')));
const InventoryStock = Loadable(lazy(() => import('../pages/dashboard/inventory/inventory-stock/InventoryStock')));
const RaisePurchaseOrder = Loadable(
  lazy(() => import('../pages/dashboard/inventory/supplied-items/raise-purchase-order/RaisePurchaseOrder'))
);
const ReceiveSuppliedItems = Loadable(
  lazy(() => import('../pages/dashboard/inventory/supplied-items/receive-supplied-items/ReceiveSuppliedItems'))
);
const ReceivePurchaseOrder = Loadable(
  lazy(() => import('../pages/dashboard/inventory/supplied-items/receive-supplied-items/ReceivePurchaseOrder'))
);
const ReceiveSuppliedOrder = Loadable(
  lazy(() => import('../pages/dashboard/inventory/supplied-items/receive-supplied-items/ReceiveSuppliedOrder'))
);
const InventoryGeneralStock = Loadable(
  lazy(() => import('../pages/dashboard/inventory/inventory-general-stock/InventoryGeneralStock'))
);

// Track A Bus
// //////////////////
const TrackABus = Loadable(lazy(() => import('../pages/dashboard/bookings/tracking/TrackABus')));
const TrackBusById = Loadable(lazy(() => import('../pages/dashboard/bookings/tracking/TrackBusById')));

// SETTINGS
// //////////////////
// //////////////////////////
const StateSettings = Loadable(lazy(() => import('../pages/dashboard/bookings/state/StateSettings')));
const RegionSettings = Loadable(lazy(() => import('../pages/dashboard/RegionSettings')));
const CountrySettings = Loadable(lazy(() => import('../pages/dashboard/CountrySettings')));
const Department = Loadable(lazy(() => import('../pages/dashboard/bookings/department/Department')));
const TripsSettings = Loadable(lazy(() => import('../pages/dashboard/TripsSetting')));
const TripDetails = Loadable(lazy(() => import('../pages/dashboard/TripDetailsSettings')));
const RouteSettings = Loadable(lazy(() => import('../pages/dashboard/RouteSettings')));
const FixedCharges = Loadable(lazy(() => import('../pages/dashboard/bookings/hire-settings/FixedCharges')));
const FuelCost = Loadable(lazy(() => import('../pages/dashboard/bookings/hire-settings/FuelCost')));
const Discount = Loadable(lazy(() => import('../pages/dashboard/Discount')));
const VehicleModel = Loadable(lazy(() => import('../pages/dashboard/bookings/vehicle-model/VehicleModel')));
const VehicleMake = Loadable(lazy(() => import('../pages/dashboard/bookings/vehicle-make/VehicleMake')));
const BankSettings = Loadable(lazy(() => import('../pages/dashboard/bookings/bank-setting/BankSettings')));
const BannerSettings = Loadable(lazy(() => import('../pages/dashboard/banner-settings/BannerSettings')));

const TerminalSettings = Loadable(lazy(() => import('../pages/dashboard/bookings/terminal-settings/TerminalSettings')));
const SubRoute = Loadable(lazy(() => import('../pages/dashboard/bookings/sub-route/SubRoute')));
const Partner = Loadable(lazy(() => import('../pages/dashboard/bookings/partner/Partner')));
const PartnerRequest = Loadable(lazy(() => import('../pages/dashboard/bookings/partner-request/PartnerRequest')));
const PartnerPayout = Loadable(lazy(() => import('../pages/dashboard/bookings/partner-payout/PartnerPayout')));
const PartnerTransaction = Loadable(
  lazy(() => import('../pages/dashboard/bookings/partner-transaction/PartnerTransaction'))
);
const BusinessPartner = Loadable(lazy(() => import('../pages/dashboard/bookings/business-partner/BusinessPartner')));
const BusinessPartnerBooking = Loadable(
  lazy(() => import('../pages/dashboard/bookings/business-partner/booking-history/BusinessPartnerBooking'))
);
const BusinessPartnerPayment = Loadable(
  lazy(() => import('../pages/dashboard/bookings/business-partner/payment-history/BusinessPartnerPayment'))
);
const PartnerSalesSummary = Loadable(
  lazy(() => import('../pages/dashboard/bookings/partner-sales-summary/PartnerSalesSummary'))
);

const DispatchPartnerType = Loadable(
  lazy(() => import('../pages/dashboard/bookings/dispatch-partner-type/DispatchPartnerType'))
);
const DispatchFee = Loadable(lazy(() => import('../pages/dashboard/bookings/dispatch-fee/DispatchFee')));
const VehicleSettings = Loadable(lazy(() => import('../pages/dashboard/bookings/vehicle-settings/VehicleSettings')));
const PickupPoint = Loadable(lazy(() => import('../pages/dashboard/bookings/pickup-point/PickupPoint')));
const BusFeature = Loadable(lazy(() => import('../pages/dashboard/BusFeature')));
const ExtendedDiscount = Loadable(lazy(() => import('../pages/dashboard/ExtendedDiscount')));
const Coupon = Loadable(lazy(() => import('../pages/dashboard/coupon/Coupon')));
const FareSettings = Loadable(lazy(() => import('../pages/dashboard/FareSettings')));
const FareCalendar = Loadable(lazy(() => import('../pages/dashboard/FareCalendar')));
const FleetMgtSettings = Loadable(lazy(() => import('../pages/dashboard/FleetMgtSettings')));
const CurrencyConverter = Loadable(lazy(() => import('../pages/dashboard/CurrencyConverter')));
const TripAvailability = Loadable(lazy(() => import('../pages/dashboard/bookings/trip-availability/TripAvailability')));
const TravelDocumentFee = Loadable(
  lazy(() => import('../pages/dashboard/bookings/travel-document-fee/TravelDocumentFee'))
);
const TravelDocumentType = Loadable(
  lazy(() => import('../pages/dashboard/bookings/travel-document-type/TravelDocumentType'))
);

// OPERATIONS
const GenerateOtp = Loadable(lazy(() => import('../pages/dashboard/operations/generating-otp/GenerateOtp')));
const RemoveRefCode = Loadable(lazy(() => import('../pages/dashboard/operations/remove-refCode/RemoveRefCode')));
const OSTFinalReport = Loadable(lazy(() => import('../pages/dashboard/operations/final-report/OSTFinalReport')));
const YesterdayTerminalFinal = Loadable(
  lazy(() => import('../pages/dashboard/operations/final-report/YesterdayTerminalFinal'))
);
const VehicleNotAccountedFor = Loadable(
  lazy(() => import('../pages/dashboard/operations/final-report/VehicleNotAccountedFor'))
);

// STARTUP OPERATION
const OSTStartupReport = Loadable(lazy(() => import('../pages/dashboard/operations/startup-report/OSTStartupReport')));
const TodayTerminalStartup = Loadable(
  lazy(() => import('../pages/dashboard/operations/startup-report/TodayTerminalStartup'))
);
const VehicleNotInEndOfToday = Loadable(
  lazy(() => import('../pages/dashboard/operations/startup-report/VehicleNotInEndOfToday'))
);
const VehicleNotAtTerminal = Loadable(
  lazy(() => import('../pages/dashboard/operations/startup-report/VehicleNotAtTerminal'))
);
const DepartureReport = Loadable(lazy(() => import('../pages/dashboard/operations/departure-report/DepartureReport')));

// CAMPUS AMBASSADOR
const CampusHome = Loadable(lazy(() => import('../pages/dashboard/campus-ambassador/CampusHome/CampusHome')));
const ViewAmbassadorDetails = Loadable(
  lazy(() => import('../pages/dashboard/campus-ambassador/CampusHome/ViewAmbassadorDetails'))
);
const AmbassadorBookingHistory = Loadable(
  lazy(() => import('../pages/dashboard/campus-ambassador/CampusHome/AmbassadorBookingHistory'))
);
const AmbassadorTicketHistory = Loadable(
  lazy(() => import('../pages/dashboard/campus-ambassador/ticket-booking-history/AmbassadorTicketHistory'))
);
const AmbassadorWithdrawal = Loadable(
  lazy(() => import('../pages/dashboard/campus-ambassador/ambassador-withdrawal/AmbassadorWithdrawal'))
);
const AmbassadorReport = Loadable(
  lazy(() => import('../pages/dashboard/campus-ambassador/ambassador-report/AmbassadorReport'))
);
const VerifyAmbasadorBooking = Loadable(
  lazy(() => import('../pages/dashboard/campus-ambassador/verify-ambassador-booking/VerifyAmbasadorBooking'))
);

// CAPTAINS
const CaptainSettings = Loadable(lazy(() => import('../pages/dashboard/captain-mgt/captains/CaptainSettings')));
const CaptainClassification = Loadable(
  lazy(() => import('../pages/dashboard/captain-mgt/captain-classification/CaptainClassification'))
);
const CaptainRequest = Loadable(lazy(() => import('../pages/dashboard/captain-mgt/captain-request/CaptainRequest')));
const CaptainSwap = Loadable(lazy(() => import('../pages/dashboard/captain-mgt/captain-swap/CaptainSwap')));
const LockedOutCaptain = Loadable(
  lazy(() => import('../pages/dashboard/captain-mgt/locked-out-captain/LockedOutCaptain'))
);
const CaptainWith17Trip = Loadable(
  lazy(() => import('../pages/dashboard/captain-mgt/captain-with-17-trips/CaptainWith17Trip'))
);
const CaptainMaintenance = Loadable(
  lazy(() => import('../pages/dashboard/captain-mgt/captain-maintenance/CaptainMaintenance'))
);
const ViewCaptainSettings = Loadable(lazy(() => import('../pages/dashboard/captain-mgt/captains/ViewCaptainSettings')));
const CaptainTripsSettings = Loadable(lazy(() => import('../pages/dashboard/CaptainTripReportSettings')));
const CaptainRecordsSettings = Loadable(
  lazy(() => import('../pages/dashboard/captain-mgt/captains/CaptainRecordsSettings'))
);
const CaptainRecord = Loadable(lazy(() => import('../pages/dashboard/captain-mgt/captains/CaptainTripHistory')));
const CaptainSalary = Loadable(lazy(() => import('../pages/dashboard/captain-mgt/captain-salary/CaptainSalary')));
const CaptainManifest = Loadable(lazy(() => import('../pages/dashboard/captain-mgt/captain-manifest/CaptainManifest')));
const SuspendedInActiveCaptains = Loadable(
  lazy(() => import('../pages/dashboard/captain-mgt/suspended-captain/SuspendedInActiveCaptains'))
);
const CaptainOverspeed = Loadable(
  lazy(() => import('../pages/dashboard/captain-mgt/captain-overspeed/CaptainOverspeed'))
);
const CaptainSalaryReport = Loadable(
  lazy(() => import('../pages/dashboard/captain-mgt/captain-salary-report/CaptainSalaryReport'))
);
const CaptainSalaryReportDetails = Loadable(
  lazy(() => import('../pages/dashboard/captain-mgt/captain-salary-report/CaptainSalaryReportDetails'))
);

const CaptainTripReport = Loadable(
  lazy(() => import('../pages/dashboard/captain-mgt/captain-trip-report/CaptainTripReport'))
);
const CaptainRating = Loadable(lazy(() => import('../pages/dashboard/captain-mgt/captain-rating/CaptainRating')));
const CaptainRatingDetail = Loadable(
  lazy(() => import('../pages/dashboard/captain-mgt/captain-rating/CaptainRatingDetail'))
);

// USER
const UserProfile = Loadable(lazy(() => import('../pages/dashboard/UserProfile')));
const UserCards = Loadable(lazy(() => import('../pages/dashboard/UserCards')));
const UserList = Loadable(lazy(() => import('../pages/dashboard/UserList')));
const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')));
const UserCreate = Loadable(lazy(() => import('../pages/dashboard/manage-employee/UserCreate')));

// APP
const Chat = Loadable(lazy(() => import('../pages/dashboard/Chat')));
const Mail = Loadable(lazy(() => import('../pages/dashboard/Mail')));
const Calendar = Loadable(lazy(() => import('../pages/dashboard/Calendar')));
const Kanban = Loadable(lazy(() => import('../pages/dashboard/Kanban')));

// TEST RENDER PAGE BY ROLE
const PermissionDenied = Loadable(lazy(() => import('../pages/dashboard/PermissionDenied')));

// MAIN
const HomePage = Loadable(lazy(() => import('../pages/Home')));
const About = Loadable(lazy(() => import('../pages/About')));
const Contact = Loadable(lazy(() => import('../pages/Contact')));
const Faqs = Loadable(lazy(() => import('../pages/Faqs')));
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Pricing = Loadable(lazy(() => import('../pages/Pricing')));
const Payment = Loadable(lazy(() => import('../pages/Payment')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const Page403 = Loadable(lazy(() => import('../pages/Page403')));
const Page404 = Loadable(lazy(() => import('../pages/Page404')));
