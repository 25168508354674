import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Navigate, useLocation } from 'react-router';
import * as AuthTypes from '../redux/auth/authTypes';

// hook
import { useSelector } from '../redux/store';
import { PATH_AUTH } from '../routes/paths';

// ----------------------------------------------------------------------

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default function AuthGuard({ children }) {
  const location = useLocation();
  const { isAuthenticated, notAuthMsg } = useSelector((state) => state.auth);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  // if (loading) {
  //   return <LoadingScreen />;
  // }

  if (notAuthMsg) {
    enqueueSnackbar(notAuthMsg, { variant: 'error' });
    dispatch({ type: AuthTypes.CLEAR_AUTH_ERROR });
  }

  if (!isAuthenticated) {
    return <Navigate to={PATH_AUTH.login} replace state={{ from: location }} />;
  }

  return <>{children}</>;
}
