import * as PaymentTypes from './paymentTypes';

const initalState = {
  loading: false,
  fetching: false,
  error: '',
  allTransactionList: { userData: [] },
  allFinalReportList: { userData: [] },
};

// eslint-disable-next-line
const paymentReducer = (state = initalState, action) => {
  switch (action.type) {
    case PaymentTypes.LOADING_PAYMENT:
      return {
        ...state,
        loading: true,
      };
    case PaymentTypes.FETCHING_PAYMENT:
      return {
        ...state,
        fetching: true,
      };

    case PaymentTypes.GET_ALL_TRANSACTION:
      return {
        ...state,
        loading: false,
        allTransactionList: action.payload,
      };

    case PaymentTypes.LISTING_RESET:
      return {
        ...state,
        loading: false,
        fetching: false,
        error: '',
      };
    case PaymentTypes.LOGOUT:
      return {
        loading: false,
        error: '',
      };

    default:
      return state;
  }
};

export default paymentReducer;
