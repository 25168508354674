export const REGION_SUCCESS = 'REGION_SUCCESS';
export const STATE_SUCCESS = 'STATE_SUCCESS';
export const COUNTRY_SUCCESS = 'COUNTRY_SUCCESS';
export const ROUTE_SUCCESS = 'ROUTE_SUCCESS';
export const ROUTE_SUCCESS_PAGINATED = 'ROUTE_SUCCESS_PAGINATED';
export const OUTGOING_JOURNEY = 'OUTGOING_JOURNEY';
export const INCOMING_JOURNEY = 'INCOMING_JOURNEY';
export const INCOMING_JOURNEY_COUNT = 'INCOMING_JOURNEY_COUNT';
export const DAILY_JOURNEY = 'DAILY_JOURNEY';
export const ONLINE_ROUTE_SUCCESS = 'ONLINE_ROUTE_SUCCESS';
export const FETCHING_BOOKING = 'FETCHING_BOOKING';
export const LOADING_INCOMPLETE = 'LOADING_INCOMPLETE';
export const LOADING_BOOKING = 'LOADING_BOOKING';
export const BOOKING_SUCCESS = 'BOOKING_SUCCESS';
export const BOOKING_ANALYSIS = 'BOOKING_ANALYSIS';
export const SET_NEW_ROUTE_FARE = 'SET_NEW_ROUTE_FARE';
export const MANAGE_BUS_INFO = 'MANAGE_BUS_INFO';
export const PICKUP_POINT_SUCCESS = 'PICKUP_POINT_SUCCESS';
export const PICKUP_REPORT = 'PICKUP_REPORT';
export const TERMINAL_SUCCESS = 'TERMINAL_SUCCESS';
export const TERMINAL_BY_DESTINATION_SUCCESS = 'TERMINAL_BY_DESTINATION_SUCCESS';
export const SHARED_JOURNEY_SUCCESS = 'SHARED_JOURNEY_SUCCESS';
export const DISCOUNT_SUCCESS = 'DISCOUNT_SUCCESS';
export const USER_BOOKING_DETAILS_SUCCESS = 'USER_BOOKING_DETAILS_SUCCESS';
export const USER_BOOKING_INFO_SUCCESS = 'USER_BOOKING_INFO_SUCCESS';
export const USER_BOOKING_INFO_CLEAR = 'USER_BOOKING_INFO_CLEAR';
export const USER_BOOKING_INFO_CLEAR_WITH_PHONE = 'USER_BOOKING_INFO_CLEAR_WITH_PHONE';
export const GET_TRIP_WITH_PHISICAL_BUS = 'GET_TRIP_WITH_PHISICAL_BUS';
export const GET_TRIP_WITH_DATE_PHISICAL_BUS = 'GET_TRIP_WITH_DATE_PHISICAL_BUS';
export const GET_TODAY_TRIP_WITH_PHISICAL_BUS = 'GET_TODAY_TRIP_WITH_PHISICAL_BUS';
export const GET_TRIPS = 'GET_TRIPS';
export const MORE_TICKET_SUCCESS = 'MORE_TICKET_SUCCESS';
export const LOADING_MORE = 'LOADING_MORE';
export const CLEAR_BOOKING_AMOUNT = 'CLEAR_BOOKING_AMOUNT';
export const SET_AVAILABLE_SEATS = 'SET_AVAILABLE_SEATS';
export const SET_BOOKING_CHARGES = 'SET_BOOKING_CHARGES';
export const SET_AVAILABLE_TRIPS = 'SET_AVAILABLE_TRIPS';
export const SET_BOOKED_VEHICLES = 'SET_BOOKED_VEHICLES';
export const SET_BOOKED_MANIFEST = 'SET_BOOKED_MANIFEST';
export const GET_PENDING_HIRE_REQUEST = 'GET_PENDING_HIRE_REQUEST';
export const GET_PENDING_HIRE_DETAILS = 'GET_PENDING_HIRE_DETAILS';
export const TRIP_LISTING_SUCCESS = 'TRIP_LISTING_SUCCESS';
export const SALES_DETAILS_SUCCESS = 'SALES_DETAILS_SUCCESS';
export const SEARCH_HIRE_BOOKINGS = 'SEARCH_HIRE_BOOKINGS';
export const GET_HIRED_VEHICLES = 'GET_HIRED_VEHICLES';
export const GET_ALL_FARES = 'GET_ALL_FARES';
export const FARE_CALENDER = 'FARE_CALENDER';
export const GET_EBM = 'GET_EBM';
export const GET_HIRE_SERVICE_REPORT = 'GET_HIRE_SERVICE_REPORT';
export const GET_ALL_FARES_AMOUNT = 'GET_ALL_FARES_AMOUNT';
export const MANIFEST_PRINT = 'MANIFEST_PRINT';
export const FIXED_CHARGES_SETTINGS = 'FIXED_CHARGES_SETTINGS';
export const FUEL_COST_SETTINGS = 'FUEL_COST_SETTINGS';
export const TICKET_SALES_RECORDS = 'TICKET_SALES_RECORDS';
export const COMBINED_BUS_SEARCH = 'COMBINED_BUS_SEARCH';
export const FLEET_HISTORY = 'FLEET_HISTORY';
export const BLOWN_BUS_REPORT = 'BLOWN_BUS_REPORT';
export const FLEET_BY_COUNTRY = 'FLEET_BY_COUNTRY';
export const FLEET_USAGE_REPORT = 'FLEET_USAGE_REPORT';
export const OPS_FLEET_SUMMARY = 'OPS_FLEET_SUMMARY';
export const OPS_FLEET_TERMINAL_SUMMARY = 'OPS_FLEET_TERMINAL_SUMMARY';
export const PARTNER_FLEET_FINANCIAL = 'PARTNER_FLEET_FINANCIAL';
export const ALL_FLEET_FINANCIAL_DETAIL = 'ALL_FLEET_FINANCIAL_DETAIL';
export const PARTNER_FLEET_REPORT = 'PARTNER_FLEET_REPORT';
export const PARTNER_FLEET_REPAIR_REPORT = 'PARTNER_FLEET_REPAIR_REPORT';
export const EXTRA_DISPATCH = 'EXTRA_DISPATCH';
export const BLOWN_BUS = 'BLOWN_BUS';
export const CAPTAIN_APPROVAL_REQUEST = 'CAPTAIN_APPROVAL_REQUEST';
export const CAPTAIN_SWAP_REQUEST = 'CAPTAIN_SWAP_REQUEST';
export const USER_BOOKING_HISTORY = 'USER_BOOKING_HISTORY';
export const USER_BOAT_HISTORY = 'USER_BOAT_HISTORY';
export const USER_BUS_HISTORY = 'USER_BUS_HISTORY';
export const USER_WALLET_INFLOW_HISTORY = 'USER_WALLET_INFLOW_HISTORY';
export const USER_WALLET_OUTFLOW_HISTORY = 'USER_WALLET_OUTFLOW_HISTORY';
export const USER_AIRTME_PAYMENT_HISTORY = 'USER_AIRTME_PAYMENT_HISTORY';
export const DISPATCH_FEE_LIST = 'DISPATCH_FEE_LIST';
export const TERMINAL_BOOKING_REPORT = 'TERMINAL_BOOKING_REPORT';
export const SHORTAGE_REPORT = 'SHORTAGE_REPORT';
export const ADVANCE_BOOKING_REPORT = 'ADVANCE_BOOKING_REPORT';
export const ADVANCE_BOOKING_REPORT_DETAILS = 'ADVANCE_BOOKING_REPORT_DETAILS';
export const TERMINAL_BOOKING_REPORT_DETAILS = 'TERMINAL_BOOKING_REPORT_DETAILS';
export const GET_BUSINESS_PARTNER = 'GET_BUSINESS_PARTNER';
export const GET_BUSINESS_PARTNER_PAYMENT = 'GET_BUSINESS_PARTNER_PAYMENT';
export const GET_BUSINESS_PARTNER_BOOKING = 'GET_BUSINESS_PARTNER_BOOKING';
export const GET_BOOKING_CHANNEL_REPORT = 'GET_BOOKING_CHANNEL_REPORT';
export const GET_DEPARTURE_REPORT = 'GET_DEPARTURE_REPORT';
export const GET_RESCHEDULE_REROUTE_REPORT = 'GET_RESCHEDULE_REROUTE_REPORT';

export const LISTING_FAILED = 'LISTING_FAILED';
export const LISTING_RESET = 'LISTING_RESET';
export const LOGOUT = 'LOGOUT';
