import Iconify from '../../../components/Iconify';
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  settings: getIcon('settings'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  booking: getIcon('ic_booking'),
  invoice: getIcon('ic_invoice'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  flag: getIcon('ic_flag'),
  menuItem: getIcon('ic_menu_item'),
};

const navConfig = [
  {
    subheader: 'general',
    items: [
      {
        title: 'Dashboard',
        roles: ['SuperAdmin', 'Administrator'],
        // roles: ['Administrator', 'GroupAccountant', 'AuditSupervisor', 'OperationHead'],
        path: PATH_DASHBOARD.general.dashboard,
        icon: ICONS.dashboard,
      },

      {
        title: 'Portal Administration',
        path: PATH_DASHBOARD.portal.root,
        icon: ICONS.invoice,
        roles: ['SuperAdmin', 'HumanResource', 'Administrator', 'GroupAccountant', 'Auditor', 'AuditSupervisor'],
        children: [
          { title: 'Manage Employee', roles: ['SuperAdmin'], path: PATH_DASHBOARD.portal.account },

          {
            title: 'Customer Records',
            roles: ['SuperAdmin', 'HumanResource', 'Administrator'],
            path: PATH_DASHBOARD.portal.customerRecord,
          },
          {
            title: 'Audit Logs',
            roles: ['SuperAdmin', 'HumanResource', 'Administrator'],
            path: PATH_DASHBOARD.portal.audit,
          },
        ],
      },

      {
        title: 'Settings & Config',
        path: '',
        icon: ICONS.settings,
        roles: [
          'WorkshopSupervisor',
          'WorkshopAdministrator',
          'FleetManager',
          'AuditSupervisor',
          'Administrator',
          'SuperAdmin',
          'OnlineBookingRepresentative',
          'InventoryAdmin',
        ],
        children: [
          {
            title: 'Booking',
            path: '#',
            roles: [
              'WorkshopSupervisor',
              'WorkshopAdministrator',
              'FleetManager',
              'AuditSupervisor',
              'Administrator',
              'SuperAdmin',
              'OnlineBookingRepresentative',
            ],
            children: [
              { title: 'state', roles: ['Administrator', 'SuperAdmin'], path: PATH_DASHBOARD.bookings.state },
              { title: 'region', roles: ['SuperAdmin', 'Administrator'], path: PATH_DASHBOARD.bookings.region },
              { title: 'country', roles: ['SuperAdmin', 'Administrator'], path: PATH_DASHBOARD.bookings.country },
              { title: 'Department', roles: ['SuperAdmin', 'Administrator'], path: PATH_DASHBOARD.bookings.department },
              {
                title: 'Currency Converter',
                roles: ['SuperAdmin', 'Administrator'],
                path: PATH_DASHBOARD.bookings.currencyConverter,
              },
              {
                title: 'trips',
                roles: ['SuperAdmin', 'Administrator', 'RegionalManager'],
                path: PATH_DASHBOARD.bookings.trips,
              },
              {
                title: 'Payment gateway',
                roles: ['SuperAdmin', 'Administrator'],
                path: PATH_DASHBOARD.bookings.paymentGateWay,
              },

              {
                title: 'Dispatch Partner Type',
                roles: ['SuperAdmin', 'Administrator'],
                path: PATH_DASHBOARD.bookings.dispatchPartnerType,
              },
              {
                title: 'Dispatch Fee',
                roles: ['SuperAdmin', 'Administrator', 'RegionalManager'],
                path: PATH_DASHBOARD.bookings.dispatchFee,
              },
              // { title: 'Trip Availability', path: PATH_DASHBOARD.bookings.tripAvailability,, },
              {
                title: 'Route settings',
                path: '#',
                roles: ['SuperAdmin', 'Administrator', 'RegionalManager'],
                children: [
                  {
                    title: 'route',
                    path: PATH_DASHBOARD.bookings.route,
                  },
                ],
              },
              {
                title: 'Vehicle',
                path: '#',
                roles: ['SuperAdmin', 'Administrator', 'FleetManager'],
                children: [
                  {
                    title: 'Vehicle Make',
                    path: PATH_DASHBOARD.bookings.vehicleMake,
                  },
                  {
                    title: 'Vehicle Model',
                    path: PATH_DASHBOARD.bookings.vehicleModel,
                  },
                  {
                    title: 'Vehicle Feature',
                    path: PATH_DASHBOARD.bookings.busFeature,
                  },
                ],
              },
              {
                title: 'Travel Documents',
                path: '#',
                roles: ['SuperAdmin', 'Administrator', 'FleetManager'],
                children: [
                  {
                    title: 'travel document type',
                    path: PATH_DASHBOARD.bookings.travelDocumentType,
                  },
                  {
                    title: 'travel document fee',
                    path: PATH_DASHBOARD.bookings.travelDocumentFee,
                  },
                ],
              },
              {
                title: 'hire settings',
                path: '#',
                roles: ['SuperAdmin', 'Administrator'],
                children: [
                  { title: 'fixed charges', path: PATH_DASHBOARD.bookings.fixedCharges },
                  { title: 'fuel cost', path: PATH_DASHBOARD.bookings.fuelCost },
                ],
              },
              {
                title: 'Fare Settings',
                path: '#',
                roles: [
                  'SuperAdmin',
                  'Administrator',
                  'FleetManager',
                  'CustomerRepresentative',
                  'CustomerRepSupervisor',
                ],
                children: [
                  {
                    title: 'Fare',
                    path: PATH_DASHBOARD.bookings.fare,
                    roles: ['SuperAdmin', 'Administrator', 'FleetManager'],
                  },
                  {
                    title: 'Fare Calendar',
                    path: PATH_DASHBOARD.bookings.fareCalendar,
                    roles: ['SuperAdmin', 'Administrator', 'FleetManager'],
                  },
                  {
                    title: 'Discount',
                    path: PATH_DASHBOARD.bookings.discount,
                    roles: ['SuperAdmin', 'Administrator', 'FleetManager'],
                  },
                  {
                    title: 'Extended Discount',
                    path: PATH_DASHBOARD.bookings.extendedDiscount,
                    roles: ['SuperAdmin', 'Administrator', 'FleetManager'],
                  },
                  {
                    title: 'Coupon',
                    path: PATH_DASHBOARD.bookings.coupon,
                    roles: [
                      'SuperAdmin',
                      'Administrator',
                      'FleetManager',
                      'CustomerRepresentative',
                      'CustomerRepSupervisor',
                    ],
                  },
                ],
              },
              {
                title: 'sub route',
                roles: ['SuperAdmin', 'Administrator', 'FleetManager', 'RegionalManager'],
                path: PATH_DASHBOARD.bookings.subRoute,
              },
              {
                title: 'pickup-point',
                roles: ['SuperAdmin', 'Administrator', 'FleetManager', 'OnlineBookingRepresentative', 'OperationHead'],
                path: PATH_DASHBOARD.bookings.pickupPoint,
              },
              {
                title: 'terminal',
                roles: ['SuperAdmin', 'Administrator', 'FleetManager'],
                path: PATH_DASHBOARD.bookings.terminalSettings,
              },
              {
                title: 'Fleet Mgt Settings',
                path: PATH_DASHBOARD.bookings.fleetMgtSettings,
                roles: [
                  'SuperAdmin',
                  'Administrator',
                  'FleetManager',
                  'InventoryAdmin',
                  'InventoryOfficer',
                  'InventorySupervisor',
                ],
              },
              { title: 'Bank', roles: ['SuperAdmin', 'Administrator'], path: PATH_DASHBOARD.bookings.bank },
              { title: 'App Content', roles: ['SuperAdmin', 'Administrator'], path: PATH_DASHBOARD.bookings.banner },
            ],
          },

          {
            title: 'Maintenance',
            path: '#',
            roles: ['SuperAdmin', 'Administrator', 'AuditSupervisor', 'WorkshopSupervisor', 'WorkshopAdministrator'],
            children: [
              {
                title: 'Maintenance-Part Types',
                path: PATH_DASHBOARD.maintenance.maintenancePartType,
              },
              {
                title: 'Workshop',
                path: PATH_DASHBOARD.maintenance.workshop,
              },
              {
                title: 'Job Card Categories',
                path: PATH_DASHBOARD.maintenance.jobCardCategories,
              },
              // { title: 'Service Level Setting', path: PATH_DASHBOARD.maintenance.jobCardCategories,, },
            ],
          },
          {
            title: 'Inventory',
            path: '#',
            roles: [
              'SuperAdmin',
              'Administrator',
              'AuditSupervisor',
              'InventoryAdmin',
              'ProcurementOfficer',
              'ChiefProcurementOfficer',
            ],
            children: [
              {
                title: 'Stores',
                path: PATH_DASHBOARD.inventory.stores,
              },
              {
                title: 'Vehicle Parts',
                path: PATH_DASHBOARD.inventory.vehiclePart,
              },
              { title: 'Vehicle Part Position', path: PATH_DASHBOARD.inventory.vehiclePartPosition },
              { title: 'Tyre', path: PATH_DASHBOARD.inventory.tyre },
              { title: 'Assign Tyre to Vehicle', path: PATH_DASHBOARD.inventory.assignTyre },
              { title: 'Vendors', path: PATH_DASHBOARD.inventory.vendor },
              { title: 'Jobcard Items', path: PATH_DASHBOARD.inventory.jobcardItems },
            ],
          },
          {
            title: 'App Content',
            path: PATH_DASHBOARD.bookings.banner,
            roles: ['SuperAdmin', 'Administrator'],
          },
        ],
      },

      // Captain start
      {
        title: 'Captain Management',
        path: '',
        icon: ICONS.analytics,
        roles: [
          'SuperAdmin',
          'Administrator',
          'Auditor',
          'AuditSupervisor',
          'GroupAccountant',
          'Captain Relation Officer',
          'Captain Relation Manager',
        ],
        children: [
          {
            title: 'Captains',
            roles: [
              'SuperAdmin',
              'Administrator',
              'Auditor',
              'AuditSupervisor',
              'GroupAccountant',
              'Captain Relation Officer',
              'Captain Relation Manager',
            ],
            path: PATH_DASHBOARD.captain.captains,
          },
          {
            title: 'Captain maintenance',
            roles: [
              'SuperAdmin',
              'Administrator',
              'Auditor',
              'AuditSupervisor',
              'GroupAccountant',
              'Captain Relation Officer',
              'Captain Relation Manager',
            ],
            path: PATH_DASHBOARD.captain.captainMaintenance,
          },
          {
            title: 'Captain classification',
            roles: [
              'SuperAdmin',
              'Administrator',
              'Auditor',
              'AuditSupervisor',
              'GroupAccountant',
              'Captain Relation Officer',
              'Captain Relation Manager',
            ],
            path: PATH_DASHBOARD.captain.captainClassification,
          },
          {
            title: 'Captain Request',
            roles: [
              'SuperAdmin',
              'Administrator',
              'Auditor',
              'AuditSupervisor',
              'GroupAccountant',
              'Captain Relation Officer',
              'Captain Relation Manager',
            ],
            path: PATH_DASHBOARD.captain.captainRequest,
          },
          {
            title: 'Captain Swap',
            roles: [
              'SuperAdmin',
              'Administrator',
              'Auditor',
              'AuditSupervisor',
              'GroupAccountant',
              'Captain Relation Officer',
              'Captain Relation Manager',
            ],
            path: PATH_DASHBOARD.captain.captainSwap,
          },
          {
            title: 'Locked Out Captains ',
            roles: [
              'SuperAdmin',
              'Administrator',
              'Auditor',
              'AuditSupervisor',
              'GroupAccountant',
              'Captain Relation Officer',
              'Captain Relation Manager',
            ],
            path: PATH_DASHBOARD.captain.lockedOutCaptain,
          },
          {
            title: 'Captains From 17 Trips',
            roles: [
              'SuperAdmin',
              'Administrator',
              'Auditor',
              'AuditSupervisor',
              'GroupAccountant',
              'Captain Relation Officer',
              'Captain Relation Manager',
            ],
            path: PATH_DASHBOARD.captain.captainWith17Trips,
          },
          {
            title: 'Captains Salary',
            path: '',
            icon: '',
            roles: [
              'SuperAdmin',
              'Administrator',
              'Auditor',
              'AuditSupervisor',
              'GroupAccountant',
              'Captain Relation Officer',
              'Captain Relation Manager',
            ],
            children: [
              { title: 'Deductions', path: PATH_DASHBOARD.captain.deductions },
              { title: 'Captain Bonuses', path: PATH_DASHBOARD.captain.captainBonuses },
              { title: 'Salary', path: PATH_DASHBOARD.captain.salary },
              { title: 'Payment Details', path: PATH_DASHBOARD.captain.paymentDetails },
              { title: 'Maintenance Incomes', path: PATH_DASHBOARD.captain.maintenanceIncomes },
              { title: 'Captain Rating Approval', path: PATH_DASHBOARD.captain.captainRatingApproval },
              { title: 'Bonus Rating Report', path: PATH_DASHBOARD.captain.bonusRatingReport },
            ],
          },
          {
            title: 'Overspeed Report',
            roles: [
              'SuperAdmin',
              'Administrator',
              'Auditor',
              'AuditSupervisor',
              'GroupAccountant',
              'Captain Relation Officer',
              'Captain Relation Manager',
            ],
            path: PATH_DASHBOARD.captain.overSpeedReport,
          },
          {
            title: 'Captain Trip Report',
            roles: [
              'SuperAdmin',
              'Administrator',
              'Auditor',
              'AuditSupervisor',
              'GroupAccountant',
              'Captain Relation Officer',
              'Captain Relation Manager',
            ],
            path: PATH_DASHBOARD.captain.captainTripReport,
          },
          {
            title: 'Captain Salary Report',
            roles: [
              'SuperAdmin',
              'Administrator',
              'Auditor',
              'AuditSupervisor',
              'GroupAccountant',
              'Captain Relation Officer',
              'Captain Relation Manager',
            ],
            path: PATH_DASHBOARD.captain.captainSalaryReport,
          },
          {
            title: 'Captain Ratings ',
            roles: [
              'SuperAdmin',
              'Administrator',
              'Auditor',
              'AuditSupervisor',
              'GroupAccountant',
              'Captain Relation Officer',
              'Captain Relation Manager',
            ],
            path: PATH_DASHBOARD.captain.captainRating,
          },
          {
            title: 'Captain Manifest ',
            roles: [
              'SuperAdmin',
              'Administrator',
              'Auditor',
              'AuditSupervisor',
              'GroupAccountant',
              'Captain Relation Officer',
              'Captain Relation Manager',
            ],
            path: PATH_DASHBOARD.captain.captainManifest,
          },
          {
            title: 'Supended Inactive Captains ',
            roles: [
              'SuperAdmin',
              'Administrator',
              'Auditor',
              'AuditSupervisor',
              'GroupAccountant',
              'Captain Relation Officer',
              'Captain Relation Manager',
            ],
            path: PATH_DASHBOARD.captain.suspendedInActiveCaptain,
          },
        ],
      },

      // OPERATIONS ///////
      {
        // 'RegionalManager',

        title: 'Operations',
        path: '',
        icon: ICONS.calendar,
        roles: [
          'SuperAdmin',
          'Administrator',
          'OperationHead',
          'OperationsManager',
          'RegionalManager',
          'HeadOfCustomerService',
        ],
        children: [
          { title: 'Generate OTP', path: PATH_DASHBOARD.operations.generateOtp },
          {
            title: 'Remove Ref-Code',
            roles: ['SuperAdmin'],
            path: PATH_DASHBOARD.operations.removeRefCode,
          },
          {
            title: 'StartUp Report',
            roles: [
              'SuperAdmin',
              'Administrator',
              'OperationHead',
              'OperationsManager',
              'RegionalManager',
              'HeadOfCustomerService',
            ],
            path: PATH_DASHBOARD.operations.ostStartupReport,
          },
          {
            title: ' Final Report',
            roles: [
              'SuperAdmin',
              'Administrator',
              'OperationHead',
              'OperationsManager',
              'RegionalManager',
              'HeadOfCustomerService',
            ],
            path: PATH_DASHBOARD.operations.ostFinalReport,
          },
          // {
          //   title: 'Terminal Today StartUp',
          //   roles: ['SuperAdmin', 'Administrator', 'OperationHead', 'RegionalManager', 'HeadOfCustomerService'],
          //   path: PATH_DASHBOARD.operations.todayStartupReport,
          // },
          // {
          //   title: 'Terminal Yesterday Final',
          //   roles: ['SuperAdmin', 'Administrator', 'OperationHead', 'RegionalManager', 'HeadOfCustomerService'],
          //   path: PATH_DASHBOARD.operations.yesterdayFinalReport,
          // },
          {
            title: 'Vehicle Not In End Of Today',
            roles: ['SuperAdmin', 'Administrator', 'OperationHead', 'RegionalManager', 'HeadOfCustomerService'],
            path: PATH_DASHBOARD.operations.vehicleNotInEndOfToday,
          },
          {
            title: 'Vehicle Not At Terminal',
            roles: ['SuperAdmin', 'Administrator', 'OperationHead', 'RegionalManager', 'HeadOfCustomerService'],
            path: PATH_DASHBOARD.operations.vehicleNotAtTerminal,
          },
          {
            title: 'Vehicle Not Accounted For',
            roles: ['SuperAdmin', 'Administrator', 'OperationHead', 'RegionalManager', 'HeadOfCustomerService'],
            path: PATH_DASHBOARD.operations.vehicleNotAccountedFor,
          },
          {
            title: 'Departure Report',
            roles: ['SuperAdmin', 'Administrator', 'OperationHead', 'RegionalManager', 'HeadOfCustomerService'],
            path: PATH_DASHBOARD.operations.departureReport,
          },
        ],
      },
      // FLEET MGT ///////

      {
        title: 'Fleet Management',
        path: '',
        icon: ICONS.menuItem,
        roles: [
          'GroupAccountant',
          'AuditSupervisor',
          'Auditor',
          'SuperAdmin',
          'Administrator',
          'FleetManager',
          'Captain Relation Officer',
          'Captain Relation Manager',
          'TerminalAccountant',
          'OperationHead',
          'OperationsManager',
          'InventoryAdmin',
          'InventoryOfficer',
          'InventorySupervisor',
          'WorkshopSupervisor',
        ],
        children: [
          {
            title: 'vehicle',
            roles: [
              'GroupAccountant',
              'AuditSupervisor',
              'Auditor',
              'SuperAdmin',
              'Administrator',
              'FleetManager',
              'Captain Relation Officer',
              'Captain Relation Manager',
              'OperationHead',
              'OperationsManager',
              'InventoryAdmin',
              'InventoryOfficer',
              'InventorySupervisor',
              'RegionalManager',
              'WorkshopSupervisor',
            ],
            path: PATH_DASHBOARD.fleet.vehicleSettings,
          },
          {
            title: 'Fleet History',
            roles: [
              'SuperAdmin',
              'Administrator',
              'Auditor',
              'AuditSupervisor',
              'CustomerRepresentative',
              'CustomerRepSupervisor',
              'FleetManager',
              'GroupAccountant',
              'HumanResource',
              'IntelligentControlUnit',
              'InventoryAdmin',
              'InventoryOfficer',
              'InventorySupervisor',
              'OnlineBookingRepresentative',
              'Partner',
              'PatrolTeamMember',
              'ReportViewer',
              'TerminalAccountant',
              'OperationHead',
              'OperationsManager',
              'TerminalOfficer',
              'Workshop',
              'WorkshopSupervisor',
              'WorkshopAdministrator',
              'Ticketer',
              'OnlineBookingManager',
              'RegionalManager',
              'EnterpriseBusinessManager',
              'Marketing&Innovation',
              'GLA',
              'IT Support',
              'ThirdPartyManager',
              'LineManager',
              'Captain Relation Officer',
              'Captain Relation Manager',
              'PartnerRelationsManager',
              'DashboardReportsAccess',
              'MarketingDashboardReportsAccess',
              'ProcurementOfficer',
              'ChiefProcurementOfficer',
              'CEO',
            ],
            path: PATH_DASHBOARD.fleet.fleetHistory,
          },
          {
            title: 'Fleet Movement Chart',
            roles: [
              'GroupAccountant',
              'AuditSupervisor',
              'Auditor',
              'SuperAdmin',
              'Administrator',
              'FleetManager',
              'Captain Relation Officer',
              'Captain Relation Manager',
              'InventoryAdmin',
              'InventoryOfficer',
              'InventorySupervisor',
              'OperationsManager',
              'WorkshopSupervisor',
            ],
            path: PATH_DASHBOARD.fleet.fleetMovementChart,
          },
          {
            title: 'Fleet Financials',
            roles: [
              'GroupAccountant',
              'AuditSupervisor',
              'Auditor',
              'SuperAdmin',
              'Administrator',
              'FleetManager',
              'Captain Relation Officer',
              'Captain Relation Manager',
            ],
            path: PATH_DASHBOARD.fleet.fleetFinancials,
          },
          {
            title: 'Fleet Financials Details',
            roles: [
              'GroupAccountant',
              'AuditSupervisor',
              'Auditor',
              'SuperAdmin',
              'Administrator',
              'FleetManager',
              'Captain Relation Officer',
              'Captain Relation Manager',
            ],
            path: PATH_DASHBOARD.fleet.allFleetFinancialsDetails,
          },
          {
            title: 'Shared Revenue',
            roles: [
              'GroupAccountant',
              'AuditSupervisor',
              'Auditor',
              'SuperAdmin',
              'Administrator',
              'FleetManager',
              'Captain Relation Officer',
              'Captain Relation Manager',
            ],
            path: PATH_DASHBOARD.fleet.sharedRevenue,
          },
          {
            title: 'Fleet Usage Report',
            roles: [
              'GroupAccountant',
              'AuditSupervisor',
              'Auditor',
              'SuperAdmin',
              'Administrator',
              'FleetManager',
              'Captain Relation Officer',
              'Captain Relation Manager',
              'WorkshopSupervisor',
            ],
            path: PATH_DASHBOARD.fleet.fleetUsageReport,
          },
          {
            title: 'Ops Fleet Usage Summary',
            roles: [
              'GroupAccountant',
              'AuditSupervisor',
              'Auditor',
              'SuperAdmin',
              'Administrator',
              'FleetManager',
              'Captain Relation Officer',
              'Captain Relation Manager',
              'TerminalAccountant',
              'WorkshopSupervisor',
            ],
            path: PATH_DASHBOARD.fleet.opsFleetUsageSummary,
          },
        ],
      },

      // Bookings & Reservation
      {
        title: 'Bookings & Reservation',
        path: '',
        icon: <Iconify icon={'eva:smartphone-fill'} width={24} height={24} />,
        roles: [
          'SuperAdmin',
          'Administrator',
          'GroupAccountant',
          'Auditor',
          'AuditSupervisor',
          'CustomerRepresentative',
          'CustomerRepSupervisor',
          'Ticketer',
          'TerminalAccountant',
          'OperationHead',
          'OperationsManager',
          'InventoryAdmin',
          'InventoryOfficer',
          'InventorySupervisor',
          'RegionalManager',
        ],
        children: [
          {
            title: 'Combined Bus',
            roles: [
              'SuperAdmin',
              'Administrator',
              'GroupAccountant',
              'Auditor',
              'AuditSupervisor',
              'TerminalAccountant',
              'OperationHead',
              'OperationsManager',
              'InventoryAdmin',
              'InventoryOfficer',
              'InventorySupervisor',
              'RegionalManager',
            ],
            path: PATH_DASHBOARD.reservation.combinedBus,
          },
          // {
          //   title: 'Customer',
          //   roles: ['SuperAdmin','Administrator', 'GroupAccountant', 'Auditor', 'AuditSupervisor'],
          //   path: PATH_DASHBOARD.reservation.customer,
          // },
          {
            title: 'advanced booking',
            path: PATH_DASHBOARD.reservation.advancedBooking,
            roles: [
              'SuperAdmin',
              'Administrator',
              'GroupAccountant',
              'Auditor',
              'AuditSupervisor',
              'Ticketer',
              'OperationsManager',
              'RegionalManager',
            ],
          },
          {
            title: 'Book on hold',
            roles: [
              'SuperAdmin',
              'Administrator',
              'GroupAccountant',
              'Auditor',
              'AuditSupervisor',
              'CustomerRepresentative',
              'CustomerRepSupervisor',
            ],
            path: PATH_DASHBOARD.reservation.bookOnHold,
          },
          {
            title: 'Mgt. Book on Hold',
            roles: [
              'SuperAdmin',
              'Administrator',
              'GroupAccountant',
              'Auditor',
              'AuditSupervisor',
              'CustomerRepresentative',
              'CustomerRepSupervisor',
            ],
            path: PATH_DASHBOARD.reservation.mgtOnHoldBookings,
          },
          {
            title: 'All Ticket Bookings',
            roles: [
              'SuperAdmin',
              'Administrator',
              'GroupAccountant',
              'Auditor',
              'AuditSupervisor',
              'TerminalAccountant',
              'OperationHead',
              'OperationsManager',
              'Ticketer',
              'CustomerRepresentative',
              'CustomerRepSupervisor',
              'RegionalManager',
            ],
            path: PATH_DASHBOARD.reservation.allTicketBookings,
          },
          {
            title: 'Ticket Sales Records',
            path: PATH_DASHBOARD.reservation.ticketSalesRecords,
            roles: [
              'SuperAdmin',
              'Administrator',
              'GroupAccountant',
              'Auditor',
              'AuditSupervisor',
              'TerminalAccountant',
              'Ticketer',
              'OperationsManager',
              'RegionalManager',
            ],
          },
          {
            title: 'Assign Bus (Scheduled Trip)',
            roles: [
              'SuperAdmin',
              'Administrator',
              'TerminalAccountant',
              'OperationHead',
              'OperationsManager',
              'TerminalOfficer',
              'RegionalManager',
            ],
            path: PATH_DASHBOARD.reservation.terminalListing,
          },
          {
            title: 'Assign Employee to Route',
            roles: [
              'SuperAdmin',
              'Administrator',
              'OperationsManager',
              'RegionalManager',
              'OperationHead',
              'TerminalOfficer',
            ],
            path: PATH_DASHBOARD.reservation.employeeRoute,
          },
          {
            title: 'Booked Vehicle',
            roles: [
              'SuperAdmin',
              'Administrator',
              'TerminalAccountant',
              'OperationHead',
              'OperationsManager',
              'Ticketer',
              'InventoryAdmin',
              'InventoryOfficer',
              'InventorySupervisor',
              'CustomerRepresentative',
              'CustomerRepSupervisor',
              'AuditSupervisor',
              'RegionalManager',
            ],
            path: PATH_DASHBOARD.reservation.bookedVehicle,
          },
          {
            title: 'Terminal booking',
            path: PATH_DASHBOARD.reservation.terminal,
            roles: [
              'SuperAdmin',
              'Administrator',
              'GroupAccountant',
              'Ticketer',
              'OperationsManager',
              'TerminalAccountant',
              'RegionalManager',
            ],
          },
          {
            title: 'Update Payment Method',
            path: PATH_DASHBOARD.reservation.updatePayment,
            roles: ['SuperAdmin', 'Administrator', 'GroupAccountant', 'AuditSupervisor'],
          },
          {
            title: 'Temporal Terminal booking',
            path: PATH_DASHBOARD.reservation.temporalTerminal,
            roles: ['SuperAdmin', 'Administrator', 'RegionalManager', 'OperationsManager'],
          },
          {
            title: 'Get Booking Analysis',
            path: PATH_DASHBOARD.reservation.bookingAnalysis,
            roles: ['SuperAdmin'],
          },

          {
            title: 'Swap Vehicle',
            path: PATH_DASHBOARD.reservation.swapVehicle,
            roles: [
              'SuperAdmin',
              'Administrator',
              'GroupAccountant',
              'Ticketer',
              'OperationsManager',
              'RegionalManager',
            ],
          },
          {
            title: 'Swap Old Trip',
            path: PATH_DASHBOARD.reservation.swapPassVehicle,
            roles: ['SuperAdmin', 'Administrator', 'RegionalManager'],
          },
          {
            title: 'Create A Bus',
            path: '',
            icon: '',
            roles: [
              'SuperAdmin',
              'Administrator',
              'OperationHead',
              'OperationsManager',
              'TerminalAccountant',
              'GroupAccountant',
              'RegionalManager',
            ],
            children: [
              { title: 'Assign Bus to Trip', path: PATH_DASHBOARD.reservation.assignBusToTrip },
              { title: 'Blown Bus', path: PATH_DASHBOARD.reservation.blownBus },
              { title: 'Blown Bus Request', path: PATH_DASHBOARD.reservation.blownBusRequest },
              { title: 'Blown Bus Report', path: PATH_DASHBOARD.reservation.blownBusReport },
              { title: 'Virtual Blown Bus', path: PATH_DASHBOARD.reservation.virtualBlownBus },
              { title: 'Virtual Bus', path: PATH_DASHBOARD.reservation.virtualBus },
              { title: 'Register Leftover Bus', path: PATH_DASHBOARD.reservation.registerLeftoverBus },
            ],
          },

          // Journey Manager ///
          {
            title: 'Journey Manager',
            path: '',
            icon: '',
            roles: [
              'Auditor',
              'AuditSupervisor',
              'SuperAdmin',
              'Administrator',
              'GroupAccountant',
              'FleetManager',
              'IntelligentControlUnit',
              'OperationHead',
              'OperationsManager',
              'TerminalAccountant',
              'RegionalManager',
            ],
            children: [
              { title: 'Extra Dispatch', path: PATH_DASHBOARD.reservation.extraDispatch },
              { title: 'Out-Going Fleet', path: PATH_DASHBOARD.reservation.outGoingFleet },
              { title: 'In-Coming Fleet', path: PATH_DASHBOARD.reservation.inComingFleet },
              { title: 'Manage Journey', path: PATH_DASHBOARD.reservation.manageJourney },
              { title: 'Incomplete Journey', path: PATH_DASHBOARD.reservation.incompleteJourney },
            ],
          },

          // End Journey Manager

          // Booking Report
          {
            title: 'Booking Report',
            path: '',
            icon: '',
            roles: [
              'Auditor',
              'AuditSupervisor',
              'SuperAdmin',
              'Administrator',
              'GroupAccountant',
              'FleetManager',
              'IntelligentControlUnit',
              'OperationHead',
              'OperationsManager',
              'TerminalAccountant',
              'Ticketer',
              'RegionalManager',
            ],
            children: [
              {
                title: 'Advanced Booking Report',
                roles: [
                  'Auditor',
                  'AuditSupervisor',
                  'SuperAdmin',
                  'Administrator',
                  'GroupAccountant',
                  'FleetManager',
                  'IntelligentControlUnit',
                  'OperationHead',
                  'OperationsManager',
                  'TerminalAccountant',
                  'Ticketer',
                  'RegionalManager',
                ],
                path: PATH_DASHBOARD.reservation.advancedBookingReport,
              },
              {
                title: 'Terminal Booking Report',
                roles: [
                  'Auditor',
                  'AuditSupervisor',
                  'SuperAdmin',
                  'Administrator',
                  'GroupAccountant',
                  'FleetManager',
                  'IntelligentControlUnit',
                  'OperationHead',
                  'OperationsManager',
                  'TerminalAccountant',
                  'Ticketer',
                  'RegionalManager',
                ],
                path: PATH_DASHBOARD.reservation.terminalBookingReport,
              },
              {
                title: 'Shortage Report',
                roles: [
                  'Auditor',
                  'AuditSupervisor',
                  'SuperAdmin',
                  'Administrator',
                  'GroupAccountant',
                  'FleetManager',
                  'IntelligentControlUnit',
                  'OperationHead',
                  'OperationsManager',
                  'TerminalAccountant',
                  'Ticketer',
                  'RegionalManager',
                ],
                path: PATH_DASHBOARD.reservation.shortageReport,
              },
              {
                title: 'Reschedule Reroute Report',
                roles: [
                  'Auditor',
                  'AuditSupervisor',
                  'SuperAdmin',
                  'Administrator',
                  'GroupAccountant',
                  'FleetManager',
                  'IntelligentControlUnit',
                  'OperationHead',
                  'OperationsManager',
                  'TerminalAccountant',
                  'Ticketer',
                  'RegionalManager',
                ],
                path: PATH_DASHBOARD.reservation.rescheduleReRouteReport,
              },
              {
                title: 'Pickup Report',
                roles: [
                  'Auditor',
                  'AuditSupervisor',
                  'SuperAdmin',
                  'Administrator',
                  'GroupAccountant',
                  'FleetManager',
                  'IntelligentControlUnit',
                  'OperationHead',
                  'TerminalAccountant',
                  'Ticketer',
                  'RegionalManager',
                ],
                path: PATH_DASHBOARD.reservation.pickupReport,
              },
            ],
          },

          // End Booking Report
          {
            title: 'Booking Channels Report',
            roles: [
              'Auditor',
              'AuditSupervisor',
              'SuperAdmin',
              'Administrator',
              'OnlineBookingManager',
              'FleetManager',
              'GroupAccountant',
              'CustomerRepresentative',
              'TerminalAccountant',
            ],
            path: PATH_DASHBOARD.bookings.bookingChannelsReport,
          },

          // Hire Service ///
          {
            title: 'Hire Service',
            path: '',
            icon: '',
            roles: [
              'Auditor',
              'AuditSupervisor',
              'SuperAdmin',
              'Administrator',
              'OnlineBookingManager',
              'FleetManager',
              'GroupAccountant',
              'CustomerRepresentative',
              'CustomerRepSupervisor',
              'OperationHead',
              'OperationsManager',
              'TerminalAccountant',
              'RegionalManager',
            ],
            children: [
              {
                title: 'Hire Booking',
                roles: [
                  'Auditor',
                  'AuditSupervisor',
                  'SuperAdmin',
                  'Administrator',
                  'OnlineBookingManager',
                  'FleetManager',
                  'GroupAccountant',
                  'CustomerRepresentative',
                  'CustomerRepSupervisor',
                  'OperationsManager',
                  'RegionalManager',
                ],
                path: PATH_DASHBOARD.hireservice.hireBooking,
              },

              {
                title: 'Assign A Bus',
                roles: [
                  'Auditor',
                  'AuditSupervisor',
                  'SuperAdmin',
                  'Administrator',
                  'OnlineBookingManager',
                  'FleetManager',
                  'GroupAccountant',
                  'CustomerRepresentative',
                  'OperationsManager',
                  'TerminalAccountant',
                  'RegionalManager',
                ],
                path: PATH_DASHBOARD.hireservice.assignABus,
              },
              {
                title: 'Assigned Vehicle',
                roles: [
                  'Auditor',
                  'AuditSupervisor',
                  'SuperAdmin',
                  'Administrator',
                  'OnlineBookingManager',
                  'FleetManager',
                  'GroupAccountant',
                  'CustomerRepresentative',
                  'OperationsManager',
                  'RegionalManager',
                ],
                path: PATH_DASHBOARD.hireservice.assignedVehicle,
              },
              {
                title: 'Manage Hire Service (Report)',
                roles: [
                  'Auditor',
                  'AuditSupervisor',
                  'SuperAdmin',
                  'Administrator',
                  'OnlineBookingManager',
                  'FleetManager',
                  'GroupAccountant',
                  'OperationsManager',
                  'TerminalAccountant',
                  'CustomerRepresentative',
                  'CustomerRepSupervisor',
                  'RegionalManager',
                ],
                path: PATH_DASHBOARD.hireservice.hireServiceReport,
              },
            ],
          },
          // End Hire Service //
        ],
      },

      // End Bookings & Reservation

      {
        title: 'Maintenance & Repair',
        path: '',
        icon: <Iconify icon={'eva:copy-fill'} width={24} height={24} />,
        roles: [
          'Workshop',
          'WorkshopSupervisor',
          'WorkshopAdministrator',
          'Auditor',
          'AuditSupervisor',
          'GroupAccountant',
          'SuperAdmin',
          'Administrator',
          'TerminalAccountant',
          'OperationHead',
          'OperationsManager',
          'InventoryAdmin',
          'InventoryOfficer',
          'InventorySupervisor',
        ],
        children: [
          // { title: 'Operations Target', path: PATH_DASHBOARD.maintenance.operationsTarget, },
          // { title: 'Maintenance Home', path: PATH_DASHBOARD.maintenance.maintenanceHome, },

          {
            title: 'Request Repair',
            roles: [
              'Auditor',
              'AuditSupervisor',
              'GroupAccountant',
              'SuperAdmin',
              'Administrator',
              'OperationHead',
              'OperationsManager',
              'Workshop',
              'WorkshopSupervisor',
              'WorkshopAdministrator',
            ],
            path: PATH_DASHBOARD.maintenance.requestRepair,
          },
          {
            title: 'Job Card Management',
            roles: [
              'Auditor',
              'AuditSupervisor',
              'GroupAccountant',
              'SuperAdmin',
              'Administrator',
              'InventoryOfficer',
              'InventorySupervisor',
              'InventoryAdmin',
              'TerminalAccountant',
              'OperationHead',
              'OperationsManager',
            ],
            path: PATH_DASHBOARD.maintenance.jobcardManagement,
          },
          {
            title: 'Open Service Vehicle ',
            roles: ['SuperAdmin', 'Administrator', 'WorkshopAdministrator'],
            path: PATH_DASHBOARD.maintenance.openServiceVehicle,
          },
          {
            title: 'Maintenance Report',
            roles: [
              'Auditor',
              'AuditSupervisor',
              'GroupAccountant',
              'SuperAdmin',
              'Administrator',
              'InventoryOfficer',
              'TerminalAccountant',
              'InventoryAdmin',
              'InventoryOfficer',
              'OperationHead',
              'InventorySupervisor',
              'WorkshopSupervisor',
            ],
            path: PATH_DASHBOARD.maintenance.maintenanceReport,
          },
          {
            title: 'Schedule Maintenance Queue',
            roles: [
              'Auditor',
              'AuditSupervisor',
              'GroupAccountant',
              'SuperAdmin',
              'Administrator',
              'InventoryAdmin',
              'InventoryOfficer',
              'InventorySupervisor',
              'WorkshopSupervisor',
            ],
            path: PATH_DASHBOARD.maintenance.scheduleMaintenanceQueue,
          },
          {
            title: 'Idle Vehicle',
            roles: [
              'Auditor',
              'AuditSupervisor',
              'GroupAccountant',
              'SuperAdmin',
              'Administrator',
              'WorkshopSupervisor',
            ],
            path: PATH_DASHBOARD.maintenance.idleVehicle,
          },
          {
            title: 'Scheduled Maintenance Mileage',
            roles: [
              'Auditor',
              'AuditSupervisor',
              'GroupAccountant',
              'SuperAdmin',
              'Administrator',
              'WorkshopSupervisor',
            ],
            path: PATH_DASHBOARD.maintenance.scheduledMaintenanceMileage,
          },
          {
            title: 'Vehicle Due For Service',
            roles: [
              'Auditor',
              'AuditSupervisor',
              'GroupAccountant',
              'SuperAdmin',
              'Administrator',
              'WorkshopSupervisor',
              'OperationsManager',
            ],
            path: PATH_DASHBOARD.maintenance.vehicleDueForService,
          },
          // { title: 'Receive Job Card Request', path: PATH_DASHBOARD.maintenance.jobcardRequest },
          // { title: 'Closed Job Card Report', path: PATH_DASHBOARD.maintenance.closedJobcardReport },
          // { title: 'Cancelled Job Cards', path: PATH_DASHBOARD.maintenance.cancelledJobcards },
          // { title: 'Job Card Status Report', path: PATH_DASHBOARD.maintenance.jobcardStatusReport },
          // { title: 'Vehicle with Job Cards', path: PATH_DASHBOARD.maintenance.vehicleWithJobcards },
          // { title: 'Captain Maintenance Costs', path: PATH_DASHBOARD.maintenance.captainMaintenanceCosts },
          // { title: 'Tyre Management', path: PATH_DASHBOARD.maintenance.tyreManagement },
          // { title: 'Tyre Swap', path: PATH_DASHBOARD.maintenance.tyreSwap },
          // { title: 'Tyre Movement', path: PATH_DASHBOARD.maintenance.tyreMovement },
          // { title: 'Tyre Report', path: PATH_DASHBOARD.maintenance.tyreReport },
          // { title: 'Mileage Report', path: PATH_DASHBOARD.maintenance.mileageReport },
        ],
      },
      // {
      //   title: 'Corporate customer',
      //   path: '',
      //   icon: ICONS.banking,
      //   children: [{ title: 'home', path: PATH_DASHBOARD.bookings.state }],
      // },

      // /////////////////////////////////////
      // End Maintenance & Repair
      {
        title: 'Procurement',
        path: '',
        icon: ICONS.user,
        roles: [
          'Auditor',
          'AuditSupervisor',
          'GroupAccountant',
          'SuperAdmin',
          'Administrator',
          'ProcurementOfficer',
          'ChiefProcurementOfficer',
          'Workshop',
          'WorkshopSupervisor',
          'WorkshopAdministrator',
          'InventoryAdmin',
          'InventoryOfficer',
          'InventorySupervisor',
          'OperationsManager',
        ],

        children: [
          {
            title: 'General Items',
            roles: [
              'Auditor',
              'AuditSupervisor',
              'GroupAccountant',
              'SuperAdmin',
              'Administrator',
              'ProcurementOfficer',
              'ChiefProcurementOfficer',
            ],
            path: PATH_DASHBOARD.procurement.generalItems,
          },
          {
            title: 'Inventory Requests',
            roles: [
              'Auditor',
              'AuditSupervisor',
              'GroupAccountant',
              'SuperAdmin',
              'Administrator',
              'ProcurementOfficer',
              'ChiefProcurementOfficer',
            ],
            path: PATH_DASHBOARD.procurement.inventoryRequests,
          },
          {
            title: 'Request Orders',
            roles: [
              'Auditor',
              'AuditSupervisor',
              'GroupAccountant',
              'SuperAdmin',
              'Administrator',
              'ChiefProcurementOfficer',
              'Workshop',
              'WorkshopSupervisor',
              'WorkshopAdministrator',
              'ProcurementOfficer',
              'InventoryAdmin',
              'InventoryOfficer',
              'InventorySupervisor',
              'OperationsManager',
            ],
            path: PATH_DASHBOARD.procurement.requestOrders,
          },
          {
            title: 'Purchased Orders',
            roles: [
              'Auditor',
              'AuditSupervisor',
              'GroupAccountant',
              'SuperAdmin',
              'Administrator',
              'ProcurementOfficer',
              'ChiefProcurementOfficer',
            ],
            path: PATH_DASHBOARD.procurement.purchasedOrders,
          },
          {
            title: 'Purchased History',
            roles: [
              'Auditor',
              'AuditSupervisor',
              'SuperAdmin',
              'Administrator',
              'ProcurementOfficer',
              'ChiefProcurementOfficer',
            ],
            path: PATH_DASHBOARD.procurement.purchasedHistory,
          },
          {
            title: 'General Request',
            roles: [
              'Auditor',
              'AuditSupervisor',
              'GroupAccountant',
              'SuperAdmin',
              'Administrator',
              'ProcurementOfficer',
              'ChiefProcurementOfficer',
              'Workshop',
              'WorkshopSupervisor',
              'WorkshopAdministrator',
              'InventoryAdmin',
              'InventoryOfficer',
              'InventorySupervisor',
              'OperationHead',
              'OperationsManager',
            ],
            path: PATH_DASHBOARD.procurement.generalRequests,
          },
          {
            title: 'Process General Request',
            roles: [
              'Auditor',
              'ProcurementOfficer',
              'AuditSupervisor',
              'GroupAccountant',
              'SuperAdmin',
              'Administrator',
              'ChiefProcurementOfficer',
            ],
            path: PATH_DASHBOARD.procurement.processGeneralRequests,
          },
          {
            title: 'General Request Items',
            roles: [
              'AuditSupervisor',
              'GroupAccountant',
              'SuperAdmin',
              'Administrator',
              'ChiefProcurementOfficer',
              'ProcurementOfficer',
              'OperationsManager',
            ],
            path: PATH_DASHBOARD.procurement.generalRequestsItems,
          },
        ],
      },
      {
        title: 'Inventory',
        path: '',
        icon: ICONS.ecommerce,
        roles: [
          'GroupAccountant',
          'Auditor',
          'AuditSupervisor',
          'SuperAdmin',
          'Administrator',
          'InventoryOfficer',
          'InventorySupervisor',
          'InventoryAdmin',
        ],
        children: [
          {
            title: 'Inventory Home',
            path: '',
            icon: '',
            roles: [
              'GroupAccountant',
              'Auditor',
              'AuditSupervisor',
              'SuperAdmin',
              'Administrator',
              'InventoryOfficer',
              'InventorySupervisor',
              'InventoryAdmin',
            ],
            children: [
              { title: 'Inventory General Stock', path: PATH_DASHBOARD.inventory.inventoryGeneralStock },
              { title: 'Inventory Stock', path: PATH_DASHBOARD.inventory.inventoryReport },
              { title: 'Inventory History', path: PATH_DASHBOARD.inventory.inventoryHistory },
            ],
          },
          {
            title: 'Inventory Request',
            path: '',
            icon: '',
            roles: [
              'GroupAccountant',
              'Auditor',
              'AuditSupervisor',
              'SuperAdmin',
              'Administrator',
              'InventoryOfficer',
              'InventorySupervisor',
              'InventoryAdmin',
            ],
            children: [
              {
                title: 'Procurement',
                path: '',
                icon: '',
                roles: [
                  'GroupAccountant',
                  'Auditor',
                  'AuditSupervisor',
                  'SuperAdmin',
                  'Administrator',
                  'InventoryOfficer',
                  'InventorySupervisor',
                  'InventoryAdmin',
                ],
                children: [
                  {
                    title: 'Item Request',
                    roles: [
                      'GroupAccountant',
                      'Auditor',
                      'AuditSupervisor',
                      'SuperAdmin',
                      'Administrator',
                      'InventoryAdmin',
                      'InventoryOfficer',
                      'InventorySupervisor',
                    ],
                    path: PATH_DASHBOARD.inventory.itemRequest,
                  },
                  {
                    title: 'Procument Items',
                    roles: [
                      'GroupAccountant',
                      'Auditor',
                      'AuditSupervisor',
                      'SuperAdmin',
                      'Administrator',
                      'InventoryOfficer',
                      'InventorySupervisor',
                    ],
                    path: PATH_DASHBOARD.inventory.procumentItems,
                  },
                ],
              },
              {
                title: 'Request For Item',
                roles: [
                  'GroupAccountant',
                  'Auditor',
                  'AuditSupervisor',
                  'SuperAdmin',
                  'Administrator',
                  'InventoryOfficer',
                  'InventorySupervisor',
                  'PatrolTeamMember',
                  'InventoryAdmin',
                ],
                path: PATH_DASHBOARD.inventory.requestItem,
              },
              {
                title: 'Approve Stock-In-Request',
                roles: [
                  'GroupAccountant',
                  'Auditor',
                  'AuditSupervisor',
                  'SuperAdmin',
                  'Administrator',
                  'InventoryOfficer',
                  'InventorySupervisor',
                  'PatrolTeamMember',
                  'InventoryAdmin',
                ],
                path: PATH_DASHBOARD.inventory.approveStockRequest,
              },
              {
                title: 'Receive Items',
                roles: [
                  'GroupAccountant',
                  'Auditor',
                  'AuditSupervisor',
                  'SuperAdmin',
                  'Administrator',
                  'InventoryOfficer',
                  'InventorySupervisor',
                  'PatrolTeamMember',
                  'InventoryAdmin',
                ],
                path: PATH_DASHBOARD.inventory.receiveItems,
              },
              {
                title: 'Sent Items',
                roles: [
                  'GroupAccountant',
                  'Auditor',
                  'AuditSupervisor',
                  'SuperAdmin',
                  'Administrator',
                  'InventoryOfficer',
                  'InventorySupervisor',
                  'PatrolTeamMember',
                  'InventoryAdmin',
                ],
                path: PATH_DASHBOARD.inventory.sentItems,
              },
            ],
          },
          {
            title: 'Supplied Items',
            path: '',
            icon: '',
            roles: [
              'GroupAccountant',
              'Auditor',
              'AuditSupervisor',
              'SuperAdmin',
              'Administrator',
              'InventoryOfficer',
              'InventorySupervisor',
              'InventoryAdmin',
            ],
            children: [
              { title: 'Raise Purchase Order', path: PATH_DASHBOARD.inventory.raisePurchaseOrder },
              { title: 'Receive Supplied Items', path: PATH_DASHBOARD.inventory.receiveSuppliedItems },
            ],
          },
        ],
      },
      {
        title: 'Partnership',
        path: '',
        icon: ICONS.mail,
        roles: [
          'SuperAdmin',
          'Administrator',
          'GroupAccountant',
          'Auditor',
          'AuditSupervisor',
          'PartnerRelationsManager',
          'EnterpriseBusinessManager',
          'partner',
        ],
        children: [
          {
            title: 'Partner',
            path: PATH_DASHBOARD.bookings.partner,
          },
          {
            title: 'Partner request',
            path: PATH_DASHBOARD.bookings.partnerRequest,
          },
          {
            title: 'Partner payout',
            path: PATH_DASHBOARD.bookings.partnerPayout,
          },
          {
            title: 'Partner transaction history',
            path: PATH_DASHBOARD.bookings.partnerTransactionHistory,
          },
          {
            title: 'Partner Sales Summary',
            path: PATH_DASHBOARD.bookings.partnerSalesSummary,
          },
          {
            title: 'Business Partners',
            path: PATH_DASHBOARD.bookings.businessPartner,
          },
        ],
      },
      {
        title: 'Ambassador',
        path: '',
        icon: ICONS.invoice,
        roles: ['SuperAdmin', 'Administrator', 'EnterpriseBusinessManager', 'PartnerRelationsManager'],
        children: [
          {
            title: 'Home',
            path: PATH_DASHBOARD.campusAmbassador.ambassadorHome,
            roles: ['SuperAdmin', 'Administrator', 'EnterpriseBusinessManager', 'PartnerRelationsManager'],
          },
          {
            title: 'Ticket Booking Histories',
            path: PATH_DASHBOARD.campusAmbassador.ambassadorTicketBooking,
            roles: ['SuperAdmin', 'Administrator', 'EnterpriseBusinessManager', 'PartnerRelationsManager'],
          },
          {
            title: 'Ambassador Withdrawal Request',
            path: PATH_DASHBOARD.campusAmbassador.ambassadorWithdrawal,
            roles: ['SuperAdmin', 'Administrator', 'EnterpriseBusinessManager', 'PartnerRelationsManager'],
          },
          {
            title: 'Verify Ambassador Booking',
            path: PATH_DASHBOARD.campusAmbassador.verifyAmbassadorBooking,
            roles: ['SuperAdmin', 'Administrator', 'EnterpriseBusinessManager', 'PartnerRelationsManager'],
          },
          {
            title: 'Ambassador Report',
            path: PATH_DASHBOARD.campusAmbassador.ambassadorReport,
            roles: ['SuperAdmin', 'Administrator', 'EnterpriseBusinessManager', 'PartnerRelationsManager'],
          },
        ],
      },
      {
        title: 'Tracking & Surveillance',
        path: '',
        icon: ICONS.kanban,
        roles: ['SuperAdmin', 'Administrator'],
        children: [
          {
            title: 'Track A Vehicle',
            path: PATH_DASHBOARD.tracking.trackBus,
            roles: ['SuperAdmin', 'Administrator'],
          },
        ],
      },
      {
        title: 'Customer Support',
        path: '',
        icon: ICONS.chat,
        roles: [
          'SuperAdmin',
          'Administrator',
          'HeadOfCustomerService',
          'CustomerRepresentative',
          'CustomerRepSupervisor',
        ],
        children: [
          {
            title: 'Azure Customer Support',
            path: PATH_DASHBOARD.general.customerSupport,
            roles: ['SuperAdmin', 'Administrator'],
          },
          // {
          //   title: 'Payment Reversal',
          //   path: PATH_DASHBOARD.general.paymentReversal,
          //   roles: ['SuperAdmin', 'Administrator'],
          // },
        ],
      },
      // {
      //   title: 'Customer Support',
      //   roles: [
      //     'SuperAdmin',
      //     'Administrator',
      //     'HeadOfCustomerService',
      //     'CustomerRepresentative',
      //     'CustomerRepSupervisor',
      //   ],
      //   path: PATH_DASHBOARD.general.customerSupport,
      //   icon: ICONS.chat,
      // },
      // { title: 'Customer Feedback', path: PATH_DASHBOARD.general.customerFeedback, icon: ICONS.dashboard },
    ],
  },
];

export default navConfig;
