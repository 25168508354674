// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  login: '/login',
  verify: '/verify',
  forgotPassword: '/forgot-password',
  resetPassword: '/reset-password',
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    dashboard: path(ROOTS_DASHBOARD, '/overview'),
    customerSupport: path(ROOTS_DASHBOARD, '/general/customer-support'),
    paymentReversal: path(ROOTS_DASHBOARD, '/general/payment-reversal'),
  },
  portal: {
    root: path(ROOTS_DASHBOARD, '/portal'),
    account: path(ROOTS_DASHBOARD, '/portal/account'),
    audit: path(ROOTS_DASHBOARD, '/portal/audit'),
    customerRecord: path(ROOTS_DASHBOARD, '/portal/customer-records'),
    bookingHistory: (email) => path(ROOTS_DASHBOARD, `/portal/customer-booking-history/${email}`),
    walletHistory: (email) => path(ROOTS_DASHBOARD, `/portal/customer-wallet-history/${email}`),
    billPaymentHistory: (email) => path(ROOTS_DASHBOARD, `/portal/customer-billPayment-history/${email}`),
  },
  bookings: {
    root: path(ROOTS_DASHBOARD, '/bookings'),
    state: path(ROOTS_DASHBOARD, '/bookings/state'),
    region: path(ROOTS_DASHBOARD, '/bookings/region'),
    country: path(ROOTS_DASHBOARD, '/bookings/country'),
    department: path(ROOTS_DASHBOARD, '/bookings/department'),
    trips: path(ROOTS_DASHBOARD, '/bookings/trips'),
    tripDetails: (id, time) => path(ROOTS_DASHBOARD, `/bookings/trip-details/${id}/${time}`),

    route: path(ROOTS_DASHBOARD, '/bookings/route'),
    paymentGateWay: path(ROOTS_DASHBOARD, '/bookings/payment-gateway'),
    fixedCharges: path(ROOTS_DASHBOARD, '/bookings/fixedCharges'),
    fuelCost: path(ROOTS_DASHBOARD, '/bookings/fuelCost'),
    routeType: path(ROOTS_DASHBOARD, '/bookings/routeType'),
    discount: path(ROOTS_DASHBOARD, '/bookings/discount'),
    vehicleModel: path(ROOTS_DASHBOARD, '/bookings/vehicle-model'),
    vehicleMake: path(ROOTS_DASHBOARD, '/bookings/vehicle-make'),
    subRoute: path(ROOTS_DASHBOARD, '/bookings/sub-route'),
    terminalSettings: path(ROOTS_DASHBOARD, '/bookings/terminal'),
    pickupPoint: path(ROOTS_DASHBOARD, '/bookings/pickup-point'),

    partner: path(ROOTS_DASHBOARD, '/bookings/partner'),
    partnerRequest: path(ROOTS_DASHBOARD, '/bookings/partner-request'),
    partnerPayout: path(ROOTS_DASHBOARD, '/bookings/partner-payout'),
    partnerTransactionHistory: path(ROOTS_DASHBOARD, '/bookings/partner-transaction-history'),
    partnerSalesSummary: path(ROOTS_DASHBOARD, '/bookings/partner-sales-summary'),
    bookingChannelsReport: path(ROOTS_DASHBOARD, '/bookings/booking-channel-report'),
    businessPartner: path(ROOTS_DASHBOARD, '/bookings/business-partner'),
    businessPartnerPaymentHistory: (businessPartnerCode) =>
      path(ROOTS_DASHBOARD, `/bookings/business-partner-payment/${businessPartnerCode}`),
    businessPartnerBookingHistory: (businessPartnerCode) =>
      path(ROOTS_DASHBOARD, `/bookings/business-partner-booking/${businessPartnerCode}`),

    dispatchPartnerType: path(ROOTS_DASHBOARD, '/bookings/dispatch-partner-type'),
    dispatchFee: path(ROOTS_DASHBOARD, '/bookings/dispatch-fee'),
    tripAvailability: path(ROOTS_DASHBOARD, '/bookings/trip-availability'),
    extendedDiscount: path(ROOTS_DASHBOARD, '/bookings/extendedDiscount'),
    coupon: path(ROOTS_DASHBOARD, '/bookings/coupon'),
    busFeature: path(ROOTS_DASHBOARD, '/bookings/busFeature'),
    fare: path(ROOTS_DASHBOARD, '/bookings/fare'),
    fareCalendar: path(ROOTS_DASHBOARD, '/bookings/fareCalendar'),
    fleetMgtSettings: path(ROOTS_DASHBOARD, '/bookings/fleetMgtSettings'),
    currencyConverter: path(ROOTS_DASHBOARD, '/bookings/currencyConverter'),
    bookedVehicle: path(ROOTS_DASHBOARD, '/bookings/bookedVehicle'),
    ticketSalesRecords: path(ROOTS_DASHBOARD, '/bookings/ticket-sales-records'),
    travelDocumentType: path(ROOTS_DASHBOARD, '/bookings/travel-document-type'),
    travelDocumentFee: path(ROOTS_DASHBOARD, '/bookings/travel-document-fee'),
    bank: path(ROOTS_DASHBOARD, '/bookings/bank'),
    banner: path(ROOTS_DASHBOARD, '/bookings/banner'),
  },

  hireservice: {
    root: path(ROOTS_DASHBOARD, '/hire-service'),
    hireBooking: path(ROOTS_DASHBOARD, '/hire-service/hire-booking'),
    assignABus: path(ROOTS_DASHBOARD, '/hire-service/assign-bus'),
    assignBusById: (id) => path(ROOTS_DASHBOARD, `/hire-service/assign-bus-by-id/${id}`),
    hireServiceRequest: (id, hiredBookingId, vehModelId) =>
      path(ROOTS_DASHBOARD, `/hire-service/hire-service-request/${id}/${hiredBookingId}/${vehModelId}`),
    assignedVehicle: path(ROOTS_DASHBOARD, '/hire-service/assigned-vehicle'),
    hireServiceReport: path(ROOTS_DASHBOARD, '/hire-service/hire-service-report'),
  },
  procurement: {
    root: path(ROOTS_DASHBOARD, '/procurement'),
    generalItems: path(ROOTS_DASHBOARD, '/procurement/general-items'),
    inventoryRequests: path(ROOTS_DASHBOARD, '/procurement/inventory-requests'),
    requestOrders: path(ROOTS_DASHBOARD, '/procurement/request-orders'),
    purchasedOrders: path(ROOTS_DASHBOARD, '/procurement/purchased-orders'),
    purchasedHistory: path(ROOTS_DASHBOARD, '/procurement/purchased-history'),
    generalRequests: path(ROOTS_DASHBOARD, '/procurement/general-requests'),
    processGeneralRequests: path(ROOTS_DASHBOARD, '/procurement/process-general-requests'),
    generalRequestsItems: path(ROOTS_DASHBOARD, '/procurement/general-requests-items'),
    processGeneralRequestDetail: (id) => path(ROOTS_DASHBOARD, `/procurement/process-general-requests-detail/${id}`),
    editProcessGeneralRequest: (id) => path(ROOTS_DASHBOARD, `/procurement/edit-process-general-requests/${id}`),
    inventoryRequestDetail: (id) => path(ROOTS_DASHBOARD, `/procurement/inventory-request-detail/${id}`),
    purchasedOrdersDetail: (id) => path(ROOTS_DASHBOARD, `/procurement/purchased-orders-detail/${id}`),
  },
  maintenance: {
    root: path(ROOTS_DASHBOARD, '/maintenance'),
    maintenancePartType: path(ROOTS_DASHBOARD, '/maintenance/maintenance-part-type'),
    workshop: path(ROOTS_DASHBOARD, '/maintenance/workshop'),
    jobCardCategories: path(ROOTS_DASHBOARD, '/maintenance/jobcard-categories'),

    operationsTarget: path(ROOTS_DASHBOARD, '/maintenance/operations-target'),
    maintenanceHome: path(ROOTS_DASHBOARD, '/maintenance/maintenance-home'),
    requestRepair: path(ROOTS_DASHBOARD, '/maintenance/request-repair'),
    jobcardRequest: path(ROOTS_DASHBOARD, '/maintenance/jobcard-request'),
    jobcardManagement: path(ROOTS_DASHBOARD, '/maintenance/jobcard-management'),
    openServiceVehicle: path(ROOTS_DASHBOARD, '/maintenance/open-service-vehicle'),
    maintenanceReport: path(ROOTS_DASHBOARD, '/maintenance/maintenance-report'),
    closedJobcardReport: path(ROOTS_DASHBOARD, '/maintenance/closed-jobcard-report'),
    cancelledJobcards: path(ROOTS_DASHBOARD, '/maintenance/cancelled-jobcards'),
    jobcardStatusReport: path(ROOTS_DASHBOARD, '/maintenance/jobcard-status-report'),
    jobCardStatusDetail: (id) => path(ROOTS_DASHBOARD, `/maintenance/jobcard-status-report/${id}`),
    vehicleWithJobcards: path(ROOTS_DASHBOARD, '/maintenance/vehicle-with-jobcards'),
    captainMaintenanceCosts: path(ROOTS_DASHBOARD, '/maintenance/captain-maintenance-costs'),
    tyreManagement: path(ROOTS_DASHBOARD, '/maintenance/tyre-management'),
    tyreSwap: path(ROOTS_DASHBOARD, '/maintenance/tyre-swap'),
    tyreMovement: path(ROOTS_DASHBOARD, '/maintenance/tyre-movement'),
    tyreReport: path(ROOTS_DASHBOARD, '/maintenance/tyre-report'),
    mileageReport: path(ROOTS_DASHBOARD, '/maintenance/mileage-report'),
    scheduleMaintenanceQueue: path(ROOTS_DASHBOARD, '/maintenance/schedule-maintenance-queue'),
    idleVehicle: path(ROOTS_DASHBOARD, '/maintenance/idle-vehicle'),
    scheduledMaintenanceMileage: path(ROOTS_DASHBOARD, '/maintenance/scheduled-maintenance-mileage'),
    vehicleDueForService: path(ROOTS_DASHBOARD, '/maintenance/vehicle-due-for-service'),
  },
  fleet: {
    root: path(ROOTS_DASHBOARD, '/fleet'),
    vehicleSettings: path(ROOTS_DASHBOARD, '/fleet/vehicle'),
    fleetHistory: path(ROOTS_DASHBOARD, '/fleet/fleet-history'),
    fleetFinancials: path(ROOTS_DASHBOARD, '/fleet/fleet-financial'),
    fleetFinancialsDetails: path(ROOTS_DASHBOARD, `/fleet/fleet-financial-details`),
    allFleetFinancialsDetails: path(ROOTS_DASHBOARD, `/fleet/all-fleet-financial-details`),
    fleetUsageReport: path(ROOTS_DASHBOARD, `/fleet/fleet-usage-report`),
    fleetUsageDetails: (type, id) => path(ROOTS_DASHBOARD, `/fleet/fleet-usage-report/${type}/${id}`),
    opsFleetUsageSummary: path(ROOTS_DASHBOARD, '/fleet/ops-fleet-usage-summary'),

    fleetMovementChart: path(ROOTS_DASHBOARD, `/fleet/fleet-movement-chart`),
    fleetMovement: (id) => path(ROOTS_DASHBOARD, `/fleet/fleet-movement/${id}`),
    opsFleetTerminalUsage: (id) => path(ROOTS_DASHBOARD, `/fleet/ops-fleet-terminal-usage/${id}`),
    fleetMovementDetails: (type, terminalId) =>
      path(ROOTS_DASHBOARD, `/fleet/fleet-movement-details/${type}/${terminalId}`),
    OpsFleetDetails: (type, terminalId) =>
      path(ROOTS_DASHBOARD, `/fleet/ops-fleet-terminal-usage/${type}/${terminalId}`),
    sharedRevenue: path(ROOTS_DASHBOARD, '/fleet/shared-revenue'),
  },
  operations: {
    root: path(ROOTS_DASHBOARD, '/operations'),
    ostFinalReport: path(ROOTS_DASHBOARD, '/operations/final-report'),
    generateOtp: path(ROOTS_DASHBOARD, '/operations/otp'),
    removeRefCode: path(ROOTS_DASHBOARD, '/operations/remove-refCode'),
    yesterdayFinalReport: path(ROOTS_DASHBOARD, '/operations/yesterday-terminal-final'),
    ostStartupReport: path(ROOTS_DASHBOARD, '/operations/startup-report'),
    todayStartupReport: path(ROOTS_DASHBOARD, '/operations/today-terminal-startup'),
    vehicleNotInEndOfToday: path(ROOTS_DASHBOARD, '/operations/vehicle-not-in-end-of-today'),
    vehicleNotAtTerminal: path(ROOTS_DASHBOARD, '/operations/vehicle-not-at-terminal'),
    vehicleNotAccountedFor: path(ROOTS_DASHBOARD, '/operations/vehicle-not-accounted-for'),
    departureReport: path(ROOTS_DASHBOARD, '/operations/departure-report'),
  },
  reservation: {
    root: path(ROOTS_DASHBOARD, '/reservation'),
    advancedBooking: path(ROOTS_DASHBOARD, '/reservation/advancedBooking'),
    bookOnHold: path(ROOTS_DASHBOARD, '/reservation/bookOnHold'),
    mgtOnHoldBookings: path(ROOTS_DASHBOARD, '/reservation/manage-onhold-bookings'),
    allTicketBookings: path(ROOTS_DASHBOARD, '/reservation/allTicketBookings'),
    bookedVehicleManifest: (id) => path(ROOTS_DASHBOARD, `/reservation/bookedVehicleManifest/${id}`),
    blownTicket: path(ROOTS_DASHBOARD, '/reservation/blownTicket'),
    ticket: (refId) => path(ROOTS_DASHBOARD, `/reservation/ticket/${refId}`),
    allTicketBookingUpdate: path(ROOTS_DASHBOARD, '/reservation/AllTicketBookingUpdate'),
    rescheduleBooking: (refCode) => path(ROOTS_DASHBOARD, `/reservation/RescheduleBooking/${refCode}`),
    addSeat: (refCode) => path(ROOTS_DASHBOARD, `/reservation/AddSeat/${refCode}`),
    rerouteBooking: (refCode) => path(ROOTS_DASHBOARD, `/reservation/RerouteBooking/${refCode}`),
    addPickUp: (refCode) => path(ROOTS_DASHBOARD, `/reservation/AddPickUp/${refCode}`),
    combinedBus: path(ROOTS_DASHBOARD, '/reservation/combined-bus'),
    combinedBusTicket: (id) => path(ROOTS_DASHBOARD, `/reservation/combined-bus/${id}`),
    // customer: path(ROOTS_DASHBOARD, '/reservation/customer'),
    terminalListing: path(ROOTS_DASHBOARD, '/reservation/terminal-listing'),
    employeeRoute: path(ROOTS_DASHBOARD, '/reservation/employee-route'),
    terminalRoute: (id) => path(ROOTS_DASHBOARD, `/reservation/terminal-route/${id}`),
    terminalBookingRoute: (id) => path(ROOTS_DASHBOARD, `/reservation/terminal-booking-route/${id}`),
    routeBuses: (type, id) => path(ROOTS_DASHBOARD, `/reservation/route-buses/${type}/${id}`),
    bookedVehicle: path(ROOTS_DASHBOARD, '/reservation/bookedVehicle'),
    ticketSalesRecords: path(ROOTS_DASHBOARD, '/reservation/ticket-sales-records'),
    terminal: path(ROOTS_DASHBOARD, '/reservation/terminal-booking'),
    updatePayment: path(ROOTS_DASHBOARD, '/reservation/update-payment-method'),
    temporalTerminal: path(ROOTS_DASHBOARD, '/reservation/temporal-terminal-booking'),
    swapVehicle: path(ROOTS_DASHBOARD, '/reservation/swap-vehicle'),
    swapPassVehicle: path(ROOTS_DASHBOARD, '/reservation/swap-pass-vehicle'),
    bookingAnalysis: path(ROOTS_DASHBOARD, '/reservation/booking-analysis'),
    bookingManager: (id) => path(ROOTS_DASHBOARD, `/reservation/booking-manager/${id}`),

    // Create a bus /////////////////////////
    assignBusToTrip: path(ROOTS_DASHBOARD, '/reservation/assign-bus-to-trip'),
    blownBus: path(ROOTS_DASHBOARD, '/reservation/blown-bus'),
    blownBusRequest: path(ROOTS_DASHBOARD, '/reservation/blown-bus-request'),
    blownBusReport: path(ROOTS_DASHBOARD, '/reservation/blown-bus-report'),
    blownBusTicket: (journeyId) => path(ROOTS_DASHBOARD, `/reservation/blown-bus/${journeyId}`),

    registerLeftoverBus: path(ROOTS_DASHBOARD, '/reservation/register-leftover-bus'),
    virtualBlownBus: path(ROOTS_DASHBOARD, '/reservation/virtual-blown-bus'),
    virtualBus: path(ROOTS_DASHBOARD, '/reservation/virtual-bus'),

    // BOOKING REPORT /////////////////////////
    advancedBookingReport: path(ROOTS_DASHBOARD, '/reservation/advance-booking-report'),
    terminalBookingReport: path(ROOTS_DASHBOARD, '/reservation/terminal-booking-report'),
    terminalBookingReportDetail: (startDate, endDate, employeeEmail) =>
      path(ROOTS_DASHBOARD, `/reservation/terminal-booking-report-detail/${startDate}/${endDate}/${employeeEmail}`),
    advanceBookingReportDetail: (startDate, endDate, employeeEmail) =>
      path(ROOTS_DASHBOARD, `/reservation/advance-booking-report-detail/${startDate}/${endDate}/${employeeEmail}`),
    shortageReport: path(ROOTS_DASHBOARD, '/reservation/shortage-report'),
    rescheduleReRouteReport: path(ROOTS_DASHBOARD, '/reservation/reschedule-reroute-report'),
    pickupReport: path(ROOTS_DASHBOARD, '/reservation/pickup-report'),

    // Journey Manager /////////////////////////
    extraDispatch: path(ROOTS_DASHBOARD, '/reservation/extra-dispatch'),
    outGoingFleet: path(ROOTS_DASHBOARD, '/reservation/out-going-fleet'),
    inComingFleet: path(ROOTS_DASHBOARD, '/reservation/incoming-fleet'),
    manageJourney: path(ROOTS_DASHBOARD, '/reservation/manage-journey'),
    manageJourneyInfo: (vehicleRegId, journeyMgtId) =>
      path(ROOTS_DASHBOARD, `/reservation/manage-journey/${vehicleRegId}/${journeyMgtId}`),
    transloadJourney: (vehicleRegId, journeyMgtId) =>
      path(ROOTS_DASHBOARD, `/reservation/transload-journey/${vehicleRegId}/${journeyMgtId}`),
    incompleteJourney: path(ROOTS_DASHBOARD, '/reservation/incomplete-journey'),
    blownFleetHistory: path(ROOTS_DASHBOARD, '/reservation/blown-fleet-history'),
    opsFleetHistory: path(ROOTS_DASHBOARD, '/reservation/ops-fleet-history'),
  },

  captain: {
    root: path(ROOTS_DASHBOARD, '/captain'),
    captains: path(ROOTS_DASHBOARD, '/captain/captains'),
    captainMaintenance: path(ROOTS_DASHBOARD, '/captain/captain-maintenance'),
    captainClassification: path(ROOTS_DASHBOARD, '/captain/captain-classification'),
    captainRequest: path(ROOTS_DASHBOARD, '/captain/captain-request'),
    captainSwap: path(ROOTS_DASHBOARD, '/captain/captain-swap'),
    lockedOutCaptain: path(ROOTS_DASHBOARD, '/captain/locked-out-captain'),
    captainWith17Trips: path(ROOTS_DASHBOARD, '/captain/captains-with-17-trips'),
    viewCaptain: (id) => path(ROOTS_DASHBOARD, `/captain/view-captain/${id}`),
    viewCaptainTrips: (id) => path(ROOTS_DASHBOARD, `/captain/view-captain-trips/${id}`),
    viewCaptainRecords: (id) => path(ROOTS_DASHBOARD, `/captain/view-captain-records/${id}`),
    viewCaptainTrip: path(ROOTS_DASHBOARD, '/captain/view-captain-trip'),

    deductions: path(ROOTS_DASHBOARD, '/captain/deductions'),
    captainBonuses: path(ROOTS_DASHBOARD, '/captain/other-income'),
    salary: path(ROOTS_DASHBOARD, '/captain/salary'),
    paymentDetails: path(ROOTS_DASHBOARD, '/captain/payment-details'),
    maintenanceIncomes: path(ROOTS_DASHBOARD, '/captain/maintenance-incomes'),
    captainRatingApproval: path(ROOTS_DASHBOARD, '/captain/captain-rating-approval'),
    bonusRatingReport: path(ROOTS_DASHBOARD, '/captain/bonus-rating-report'),
    overSpeedReport: path(ROOTS_DASHBOARD, '/captain/overspeed-report'),
    captainTripReport: path(ROOTS_DASHBOARD, '/captain/captain-trip-report'),
    captainSalaryReport: path(ROOTS_DASHBOARD, '/captain/captain-salary-report'),

    captainSalaryReportDetails: (startDate, endDate, captainCode) =>
      path(ROOTS_DASHBOARD, `/captain/captain-salary-report-details/${startDate}/${endDate}/${captainCode}`),
    captainRating: path(ROOTS_DASHBOARD, '/captain/captain-rating'),
    captainRatingDetail: (id) => path(ROOTS_DASHBOARD, `/captain/trip-rating/${id}`),
    captainManifest: path(ROOTS_DASHBOARD, '/captain/captain-manifest'),
    suspendedInActiveCaptain: path(ROOTS_DASHBOARD, '/captain/suspended-inactive-captain'),
  },
  inventory: {
    root: path(ROOTS_DASHBOARD, '/inventory'),
    stores: path(ROOTS_DASHBOARD, '/inventory/stores'),
    vehiclePart: path(ROOTS_DASHBOARD, '/inventory/vehicle-part'),
    vehiclePartPosition: path(ROOTS_DASHBOARD, '/inventory/vehicle-part-position'),
    jobcardItems: path(ROOTS_DASHBOARD, '/inventory/jobcard-items'),
    tyre: path(ROOTS_DASHBOARD, '/inventory/tyre'),
    assignTyre: path(ROOTS_DASHBOARD, '/inventory/vehicle-tyre'),
    vendor: path(ROOTS_DASHBOARD, '/inventory/vendor'),

    inventoryHistory: path(ROOTS_DASHBOARD, '/inventory/history'),
    inventoryStore: (id) => path(ROOTS_DASHBOARD, `/inventory/store-details/${id}`),
    inventoryReport: path(ROOTS_DASHBOARD, '/inventory/stock'),
    inventoryGeneralStock: path(ROOTS_DASHBOARD, '/inventory/general-stock'),
    itemRequest: path(ROOTS_DASHBOARD, '/inventory/item-request'),
    procumentItems: path(ROOTS_DASHBOARD, '/inventory/procument-items'),
    inventoryProcumentItemDetail: (id) => path(ROOTS_DASHBOARD, `/inventory/procument-items-detail/${id}`),
    requestItem: path(ROOTS_DASHBOARD, '/inventory/request-item'),
    approveStockRequest: path(ROOTS_DASHBOARD, '/inventory/approve-stock-request'),
    sentItems: path(ROOTS_DASHBOARD, '/inventory/sent-items'),
    receiveItems: path(ROOTS_DASHBOARD, '/inventory/receive-items'),
    raisePurchaseOrder: path(ROOTS_DASHBOARD, '/inventory/raise-purchase-order'),
    receiveSuppliedItems: path(ROOTS_DASHBOARD, '/inventory/receive-supplied-items'),
    receivePurchasedOrder: (id) => path(ROOTS_DASHBOARD, `/inventory/purchased-orders-detail/${id}`),
    receiveItemSupplied: (id) => path(ROOTS_DASHBOARD, `/inventory/receive-supplied-item/${id}`),
  },
  campusAmbassador: {
    root: path(ROOTS_DASHBOARD, '/campusAmbassador'),
    ambassadorHome: path(ROOTS_DASHBOARD, '/campusAmbassador/ambassador-home'),
    ambassadorDetails: (id) => path(ROOTS_DASHBOARD, `/campusAmbassador/ambassador-detail/${id}`),
    ambassadorBookingHistory: (email) => path(ROOTS_DASHBOARD, `/campusAmbassador/ambassador-booking/${email}`),
    ambassadorTicketBooking: path(ROOTS_DASHBOARD, '/campusAmbassador/ambassador-ticket-booking'),
    ambassadorWithdrawal: path(ROOTS_DASHBOARD, '/campusAmbassador/ambassador-withdrawal'),
    ambassadorReport: path(ROOTS_DASHBOARD, '/campusAmbassador/ambassador-report'),
    verifyAmbassadorBooking: path(ROOTS_DASHBOARD, '/campusAmbassador/verify-ambassador-booking'),
    // inventoryStore: (id) => path(ROOTS_DASHBOARD, `/inventory/store-details/${id}`),
  },
  tracking: {
    root: path(ROOTS_DASHBOARD, '/tracking'),
    trackBus: path(ROOTS_DASHBOARD, `/tracking/track-bus`),
    trackBusById: (t, i) => path(ROOTS_DASHBOARD, `/tracking/track-bus/${t}/${i}`),
  },
  mail: {
    root: path(ROOTS_DASHBOARD, '/mail'),
    all: path(ROOTS_DASHBOARD, '/mail/all'),
  },
  chat: {
    root: path(ROOTS_DASHBOARD, '/chat'),
    new: path(ROOTS_DASHBOARD, '/chat/new'),
    view: (name) => path(ROOTS_DASHBOARD, `/chat/${name}`),
  },
  calendar: path(ROOTS_DASHBOARD, '/calendar'),
  kanban: path(ROOTS_DASHBOARD, '/kanban'),
  permissionDenied: path(ROOTS_DASHBOARD, '/permission-denied'),
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    new: path(ROOTS_DASHBOARD, '/user/new'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    account: path(ROOTS_DASHBOARD, '/user/account'),
    edit: (email) => path(ROOTS_DASHBOARD, `/user/${email}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
  },
  eCommerce: {
    root: path(ROOTS_DASHBOARD, '/e-commerce'),
    shop: path(ROOTS_DASHBOARD, '/e-commerce/shop'),
    list: path(ROOTS_DASHBOARD, '/e-commerce/list'),
    checkout: path(ROOTS_DASHBOARD, '/e-commerce/checkout'),
    new: path(ROOTS_DASHBOARD, '/e-commerce/product/new'),
    view: (name) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}`),
    edit: (name) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-blazer-low-77-vintage/edit'),
    demoView: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-air-force-1-ndestrukt'),
  },
  invoice: {
    root: path(ROOTS_DASHBOARD, '/invoice'),
    list: path(ROOTS_DASHBOARD, '/invoice/list'),
    new: path(ROOTS_DASHBOARD, '/invoice/new'),
    view: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}`),
    edit: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1/edit'),
    demoView: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5'),
  },
  blog: {
    root: path(ROOTS_DASHBOARD, '/blog'),
    posts: path(ROOTS_DASHBOARD, '/blog/posts'),
    new: path(ROOTS_DASHBOARD, '/blog/new'),
    view: (title) => path(ROOTS_DASHBOARD, `/blog/post/${title}`),
    demoView: path(ROOTS_DASHBOARD, '/blog/post/apply-these-7-secret-techniques-to-improve-event'),
  },
};
