import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
// slices
import calendarReducer from './slices/calendar';
import authReducer from './auth/authReducer';
import userReducer from './user/userReducer';
import modalReducer from './modal/modalReducer';
import bookingsReducer from './bookings/bookingsReducer';
import settingsReducer from './settings/settingsReducer';
import inventoryReducer from './inventory/inventoryReducer';
import searchReducer from './search/searchReducer';
import captainReducer from './captains/captainReducer';
import ambassadorReducer from './ambassador/ambassadorReducer';
import operationReducer from './operations/operationReducer';
import procurementReducer from './procurement/procurementReducer';
import paymentReducer from './payment/paymentReducer';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['auth'],
};

const rootReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  modal: modalReducer,
  bookings: bookingsReducer,
  settings: settingsReducer,
  inventory: inventoryReducer,
  procurement: procurementReducer,
  ambassador: ambassadorReducer,
  operations: operationReducer,
  payments: paymentReducer,
  captains: captainReducer,
  search: searchReducer,
  calendar: calendarReducer,
});

export { rootPersistConfig, rootReducer };
