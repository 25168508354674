export const ENV = {
  onboarding: 'https://onboarding.gigmobility.co/apiV2',
  booking: 'https://booking.gigmobility.co/apiV2',
  settings: 'https://settings.gigmobility.co/apiV2',
  captain: 'https://captain.gigmobility.co/apiV2',
  partner: 'https://partner.gigmobility.co/apiV2',
  inventory: 'https://inventory.gigmobility.co/apiV2',
  procurement: 'https://procurement.gigmobility.co/apiV2',
  ambassador: 'https://ambassador.gigmobility.co/apiV2',
  operations: 'https://operations.gigmobility.co/apiV2',
  payments: 'https://payment.gigmobility.co/apiV2',
};

// git update-index --skip-worktree src/env.js

// git update-index --no-skip-worktree src/env.js
